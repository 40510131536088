
import { ERROR_COMPANY_AUTHENTICATE,REQUEST_COMPANY_AUTHENTICATE,SET_COMPANY_AUTHENTICATE } from "./companyDetail.action_type";
const intialState = {
    loading: false,
    companyAuthenticate: {},
    error: null,
}

const reducer = (state = intialState, action) => {
    // console.log(state.companyAuthenticate);
    switch (action.type) {
        case REQUEST_COMPANY_AUTHENTICATE:
            return {
                ...state,
                loading: true,

            }
        case SET_COMPANY_AUTHENTICATE:
            return {
                ...state,
                loading: false,
               companyAuthenticate: action.payload,
                error: null
            }
        case ERROR_COMPANY_AUTHENTICATE:
            return {
                ...state,
                error: action.payload,
            }
        default: return state
    }


}
export { reducer };