
import {
  REQUEST_DASHBOARD_INFECTED_DATA,
  REQUEST_FAILED_DASHBOARD_INFECTED_DATA,
  RECEIVE_DASHBOARD_INFECTED_DATA,
 
} from './dashboard.action_type';

const initialState = {
  sortBy: 'createdAt', // Default value for sortBy
  sortOrder: -1, // Default value for sortOrder
  // data: [],
  loadingInfected: false,
  infectedData: [],
  totalRecords: 0,
  errorInfected: null,

  filters: {},
  pageSize: 100,
  pageNumber: 1,
  lastSortBy: null,
  lastSortOrder: null,
};

const reducer = (state = initialState, action) => {
  // console.log(state.infectedData, "infecteddata");
  switch (action.type) {
    case REQUEST_DASHBOARD_INFECTED_DATA:
      return {
        ...state,
        loadingInfected: true,
      };
    case RECEIVE_DASHBOARD_INFECTED_DATA:
      return {
        ...state,
        loadingInfected: false,
       infectedData: action.payload.infected_computer,
        // linkedDevices:action.payload.linkedDevices,
        // unlinkedDevices:action.payload.unlinkedDevices,
        // totalRecords: action.payload.totalRecords,
        errorInfected: null,
      };
    case REQUEST_FAILED_DASHBOARD_INFECTED_DATA:
      return {
        ...state,
        loadingInfected: false,
        errorInfected: action.payload,
      };
  
    default:
      return state;
  }
};

export { reducer };
