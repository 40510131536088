import { RECEIVE_ALL_ORG, REQUEST_FAILED_ALL_ORG, REQUEST_ALL_ORG, SET_FILTER_ALL_ORG, SET_PAGE_NUMBER_ALL_ORG, SET_PAGE_SIZE_ALL_ORG, SET_SORT_OPTIONS_ALL_ORG } from "./allOrg.action_type"

const initialState = {
    loading: false,

    sortBy: 'createdAt', // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    loading: false,
    allOrgList: [],
    totalRecords: 0,
    error: null,
    filters: {},
    pageSize: 100,
    pageNumber: 1,
    error: false,

}
const reducer = (state = initialState, action) => {
// console.log(state,"state all org");

    switch (action.type) {
        case REQUEST_ALL_ORG:
            return {
                ...state,
                loading: true,
            }
        case RECEIVE_ALL_ORG:
            return {
                ...state,
                loading: false,
               allOrgList: action?.payload?.data?.data,
                totalRecords:action?.payload?.data?.totalRecords,
            }
        case REQUEST_FAILED_ALL_ORG:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case SET_SORT_OPTIONS_ALL_ORG:
            return {
                ...state,
                lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
                lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
                sortBy: action.payload.sortBy,
                sortOrder: action.payload.sortOrder,
            };
        case SET_PAGE_SIZE_ALL_ORG:
            return {
                ...state,
                pageSize: action.payload,
            };
        case SET_PAGE_NUMBER_ALL_ORG:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_FILTER_ALL_ORG:
            return {
                ...state,
                filters: action.payload,
            }

        default: return state;
    }
}
export { reducer };