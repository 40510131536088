
import {
    REQUEST_DASHBOARD_WHITELIST,
    REQUEST_FAILED_DASHBOARD_WHITELIST,
    RECEIVE_DASHBOARD_WHITELIST,
   
  } from './dashboard.action_type';
  
  const initialState = {
    sortBy: 'createdAt', // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    // data: [],
    loadingInfected: false,
    whitelistData: [],
    totalRecords: 0,
    errorInfected: null,
  
    filters: {},
    pageSize: 100,
    pageNumber: 1,
    lastSortBy: null,
    lastSortOrder: null,
  };
  
  const reducer = (state = initialState, action) => {
    // console.log(state.whitelistData, "whitelistdata");
    switch (action.type) {
      case REQUEST_DASHBOARD_WHITELIST:
        return {
          ...state,
          loadingInfected: true,
        };
      case RECEIVE_DASHBOARD_WHITELIST:
        return {
          ...state,
          loadingInfected: false,
         whitelistData: action.payload.data,
          // linkedDevices:action.payload.linkedDevices,
          // unlinkedDevices:action.payload.unlinkedDevices,
          // totalRecords: action.payload.totalRecords,
          errorInfected: null,
        };
      case REQUEST_FAILED_DASHBOARD_WHITELIST:
        return {
          ...state,
          loadingInfected: false,
          errorInfected: action.payload,
        };
    
      default:
        return state;
    }
  };
  
  export { reducer };
  