import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import IR from "./pages/IR";
import DLP from "./pages/DLP";
import Home from "./Home";
import Alerts from "./webapplication/pages/Alerts";
import SubPage from "./webapplication/pages/SubPage";
import AlertInstance from "./webapplication/pages/AlertInstance";
import { useDispatch, useSelector } from "react-redux";
import UuidAlerts from "./webapplication/pages/UuidAlerts";
import AllInstance from "./webapplication/pages/AllInstance";
import ActionCenterComputer from "./webapplication/pages/ActionCenterComputer";
import DirectoryService from "./webapplication/pages/DirectoryService";
import WorkQueue from "./webapplication/pages/WorkQueue";
// import CreateLicense from './webapplication/pages/licensing/CreateLicense';
import LinkingLicense from './webapplication/pages/licensing/LinkingLicense';
import LinkingLicence1 from './webapplication/pages/licensing/LinkingLicence1';
import { Installer } from './webapplication/pages/Installer';
import { Dashboard } from './webapplication/pages/Dashboard';
import SignUp1 from './pages/SignUp1';
import ThankYouSign from './pages/signUp/ThankYouSign';
import 'react-tabs/style/react-tabs.css'; // Import default styles for react-tabs

// import SignUp from './pages/signUp/PreSignUp';
// import PreSignUp1 from './pages/signUp/PreSignUp1';
import Login from './pages/login/Login';
import CompleteSignUp from './pages/signUp/CompletesSignUp';

import ForgotPassword from './pages/login/ForgotPassword';
import ErrorPage from './pages/ErrorPage';
import PreSignUp1 from './pages/Particles';
import LicenseDetail from './webapplication/pages/licensing/LicenseDetail';
import SubPage1 from './webapplication/pages/SubPage1';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Installer1 } from './webapplication/pages/Installer1';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import CreateLicense2 from './webapplication/pages/licensing/CreateLicense2';
import LicenseDetail1 from './webapplication/pages/licensing/LicenseDetail1';

import LicenseHistoryCustomer from './webapplication/pages/licensing/LicenseHistoryCustomer';
import LicenseHistoryAdmin from './webapplication/pages/licensing/LicenseHistoryAdmin';

import { checkAdminAuth } from './Redux/adminAuth/adminAuth.action';
import { checkUserAuth } from './Redux/userAuth/userAuth.action';
import { setUserId } from './Redux/companyid/companyid.action';
import Error from './pages/Error';
// import LicenseHistoryCustomer from './webapplication/pages/licensing/LicenseHistoryCustomer';
// import LinkedDevices from './webapplication/pages/licensing/LinkedDevices';
// import LicenseHistoryKeyWise from './webapplication/pages/licensing/LicenseHistoryKeyWise';
// import UserRoles from './webapplication/pages/UserRoles';
// import NewUserSignup from './webapplication/pages/NewUserSignup';
// import VerifyUser from './webapplication/pages/VerifyUser';
// import Account from './webapplication/pages/settings/Account';
// import SignUp from './pages/signUp/SignUp';
// import AllOrganisation from './webapplication/pages/adminPages/AllOrganisation';
// import AllCompanies from './webapplication/pages/adminPages/AllCompanies';
// import Notification from './webapplication/pages/administrator/Notification';
// import OrganisationSettings from './webapplication/pages/administrator/OrganisationSettings';
import Release from './webapplication/pages/adminPages/Release';
import Rough from './webapplication/pages/adminPages/Rough';
function App() {
  const dispatch = useDispatch();
  const { companyId, userId, adminAuthData } = useSelector((state) => ({
    companyId: state.companyid.companyId,
    userId: state.companyid.userId,
    // permissions: state.companyid.permissions,
    adminAuthData: state.adminAuth.adminAuthData,
    // userAuthData: state.userAuth.userAuthData
  }));

  return (
    <BrowserRouter>
      <div className="relative">

        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dlp" element={<DLP />} />
          <Route path="/ir-services" element={<IR />} />
          {/* <Route path='/presignup' element={<PreSignUp1 />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/thankyouSignUp" element={<ThankYouSign />} />

          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/complete-signup/:uniqueid" element={<CompleteSignUp />} />
          <Route path="/cms/history" element={<LicenseHistoryCustomer />} />
          <Route path="/cms/account/:userid" element={
            permissions.find((path) => path?.pagePath?.includes('/cms/account/') && path?.permissionLevel === 'no_access')
              ? <Error /> : <Account />} />
          <Route path="/cms/allOrg/:userid" element={<AllOrganisation />} />
          <Route path="/cms/allCompany/:userid" element={<AllCompanies />} />
          <Route
            path="/cms/admin/notifications/:userid"
            element={
              permissions.find((path) => path?.pagePath?.includes('/cms/admin/notifications/') && path?.permissionLevel === 'no_access')
                ? <Error /> : <Notification />}
          />
          <Route
            path="/cms/admin/organisation/:userid"
            element={userAuthData?.permissions?.[0]?.permissionLevel === 'no_access' ? <Error /> : <OrganisationSettings />}
          />
          <Route path="/cms/overview/:userid" element={<Dashboard />} />
          <Route
            path="/cms/licenseInfo/:userId"
            element={
              permissions.find((path) => path?.pagePath?.includes('/cms/licenseInfo/') && path?.permissionLevel === 'no_access')
                ? <Error />
                : <LicenseDetail1 />
            }
          /> */}


          {/* <Route path="/cms/userhistory/:userid" element={<LicenseHistoryCustomer />} /> */}

          {/* 
          <Route path="/cms/licenseHistory/:key/:userid" element={<LicenseHistoryAdmin />} />

          <Route path="/cms/linkeddevices/:product/:userid" element={
            permissions.find((path) => path?.pagePath?.includes('/cms/linkeddevices/') && path?.permissionLevel === 'no_access')
              ? <Error /> : <LinkedDevices />} />

          <Route path="/cms/installer/:companyid/:userid" element={
            permissions.find((path) => path?.pagePath?.includes('/cms/installer/') && path?.permissionLevel === 'no_access')
              ? <Error /> : <Installer1 />} />
          <Route
            path='/cms/userroles/:userid'
            element={
              permissions.find((path) => path?.pagePath?.includes('/cms/userroles/'))?.permissionLevel === 'no_access'
                ? <Error />
                : <UserRoles />
            }
          /> */}

          {/* <Route path="/cannotget" element={<Error />} />
          <Route path="/cms/newuser/:uniqueId" element={<NewUserSignup />} />
          <Route path="/cms/createLicense/:userId" element={<CreateLicense2 />} /> */}
          {/* Route for base URL /cms/release/manage */}
          <Route
            path="/cms/release/manage/:userid"
            element={ <Release />}
          />
          {/* Route for dynamic path /cms/release/manage/:path+ */}
          {/* <Route path="/cms/release/manage/:userid/:path+" element={<Release />} /> */}
          {/* <Route path="/cms/verifyUser/:userId" element={<VerifyUser />} /> */}
          {/* <Route path='/clip' element={<CopyToClip/>}/> */}
          {/* 
          <Route path="/cms/alerts/:companyId/:userid" element={<UuidAlerts />} />
          <Route path="/cms/alertInstance/:companyId/:fileid/:userid" element={<AlertInstance />} />
          <Route path="/cms/allinstances/:companyId/:userid" element={<AllInstance />} />
          <Route path="/cms/acComputer/:companyId/:userid" element={<ActionCenterComputer />} />
          <Route path="/cms/setting/directory/:companyId/:userId" element={<DirectoryService />} />
          <Route path="/cms/workQueue/:companyId/:userId" element={<WorkQueue />} /> */}

        </Routes>

      </div>
    </BrowserRouter>
  );
}

export default App;
