import React from "react";
import { styles } from "./styles";
// import { FaBeer } from "react-icons/fa";
import { AiFillFacebook } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="w-full mx-auto h-full   bg-gradient-to-b from-secondary to-primary footer">
        <div
          className={` inset-0 top-[20px] container mx-auto ${styles.paddingX} `}
        >
          {/* <hr className='' /> */}
          {/* <div className="border-t-2 absolute top-96 border-black-300"></div> */}
          <div className="grid grid-cols-2 justify-evenly py-[1vh] ">
            <div className="flex flex-col justify-around pl-4 lg:pb-8 pb-4">
              {/* <h1>Get to know us</h1> */}
              <div className="pt-[2vh]"> <h2 className="text-gray-700 font-semibold ">Offerings</h2>
              </div>
              <p className="pt-3"><a href="/ams" className="text-gray-700 text-sm hover:text-gray-900">EDR</a></p>
              <p><a href="/dlp" className="text-gray-700 text-sm hover:text-gray-900">Data Leak Prevention</a></p>
              <p><a href="/ir-services" className="text-gray-700 text-sm hover:text-gray-900">IR Services</a></p>


            </div>
            <div className="flex flex-col justify-around align-middle   px-[1vw]">
              <h1 className="text-xl font-semibold pt-[1vh]">Follow us at</h1>
              <div className="flex flex-row gap-3">
                <div className=" border border-black rounded-full p-1 hover:bg-white">
                  <a href="https://www.linkedin.com/company/matisoft-cyber-security-labs/" target="_blank"> <AiFillLinkedin className="text-2xl" /></a>
                </div>
                <div className=" border border-black rounded-full p-1 hover:bg-white">
                  {" "}
                  <a href="https://twitter.com/MatisoftLabs" target="_blank"><AiOutlineTwitter className="text-2xl" /></a>
                </div>
                <div className=" border border-black rounded-full p-1 hover:bg-white ">
                  {/* <FaBeer /> */}
                  <a href="https://www.facebook.com/profile.php?id=100053684905762" target="_blank"><AiFillFacebook className="text-2xl" /></a>
                </div>


              </div>
              <div></div>
            </div>
          </div>
          <div className="pt-0 sm:pt-[-20px]  ">
            <hr />
            <div className="flex flex-col lg:flex-row justify-between align-middle lg:pt-2 pt-0 font-low gap-0 gap-y-[-10%] px-6">
              <div className={`text-gray-200 font-detail text-sm  `}> <a href="/privacy-policy">Privacy Policy</a>
              </div>
              <div className={`text-gray-200 font-detail text-sm  `}>© {new Date().getFullYear()} Matisoft Cyber Security Labs Pvt. Ltd. All rights reserved.
              </div></div>
          </div>
        </div>
      </div>



    </>
  );
};

export default Footer;
