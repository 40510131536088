import React, { useEffect } from 'react';

const CookiesBanner = () => {
  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted && cookiesAccepted === 'true') {
      document.getElementById("cookies-div").style.display = "none";
    }
  }, []);

  const acceptCookies = () => {
    document.getElementById("cookies-div").style.display = "none";
    localStorage.setItem("cookiesAccepted", true);
  };

  return (
    <div className="cookies-div" id="cookies-div">
      <div className="container mx-auto">
        <div className=" mx-auto flex flex-col md:flex-row justify-between">
          <div className="col-lg-10 col-sm-9">
            <div className="cookies-content " >
              <p className='font-thin'>We use cookie to ensure that we give you the best experience on our website. If you continue to browse this site,<br /> we will assume that you agree to our <a href="/cookies-policy" target="_blank">cookie policy</a>.</p>
            </div>
          </div>
          <div className="col-lg-2 col-sm-3">
            <div className="cookies-btn">
              <button className="btn btn-primary" onClick={acceptCookies}>Accept & Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesBanner;