import React from "react";
import { styles } from "../styles";

const UseCase = () => {
  return (
    <div className=" w-full h-full  xl:h-screen  mx-auto   bg-[#C9EDFA] py-[10vh] relative" id="useCases"> 
      <div
        className={`  inset-0 container mx-auto ${styles.paddingX} `}
      >
          <div className="useCase-underline absolute top-0  py-[4vh]">
        <h1 className="text-secondary text-4xl font-extrabold font-heading text-center mx-auto  font-high ">Use Cases</h1></div>
       
        <div class={` grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 relative pt-[2vh] mx-auto ${styles.sectionText}`}>
  {/* <!-- Box 1 --> */}
  <div class= {`p-4 rounded-md  box  `}>
    <p class="text-justify">Compliant with<span class="text-[1.1em]"> CERT-In </span>Direction & Advisory</p>
  </div>

  {/* <!-- Box 2 --> */}
  <div class=" p-4 rounded-md h-44 box">
    <p class="text-justify">Share data with<span class="text-[1.1em]"> Auditors </span>with 100% security & accountability using
              USB Vaults</p>
  </div>

  {/* <!-- Box 3 --> */}
 
  <div class=" p-4 rounded-md h-44 box ">
    <p class="text-justify ">Data transfers to<span class="text-[1.1em]"> critical computers </span>like OT networks, ATMs, Air-gapped networks etc</p>
  </div>

  {/* <!-- Box 4 --> */}
  <div class=" p-4 rounded-md h-44 box">
    <p class="text-justify">Secure<span class="text-[1.1em]"> Medical records </span> as this is the most sought-after data by hackers</p>
  </div>

  {/* <!-- Box 5 --> */}
  <div class=" p-4 rounded-md h-44 box">
    <p class="text-justify">Share data with your<span class="text-[1.1em]"> Vendors, Regulatory bodies, Law enforcement, and franchise partners </span>with 100% privacy, accountability & security</p>
  </div>

  {/* <!-- Box 6 --> */}
  <div class=" p-4 rounded-md h-44 box">
    <p class="text-justify">Our software provides a<span class="text-[1.1em]"> VPN on USB drives </span>to transfer data which is tamper proof & snooping-proof</p>
  </div>

  {/* <!-- Box 7 --> */}
  <div class=" p-4 rounded-md h-44 box">
    <p class="text-justify">Take<span class="text-[1.1em]"> data backups </span> on USB hard disks which are 100% secure</p>
  </div>

  {/* <!-- Box 8 --> */}
  <div class=" p-4 rounded-md h-44 box">
    <p class="text-justify"><span class="text-[1.1em]">Media companies</span> can transfer content in a 100% private, accountable & secure manner</p>
  </div>

</div>

      </div>
    </div>
  );
};

export default UseCase;
