import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { styles } from "./styles";
import { AiOutlineClose } from "react-icons/ai";
import { LuMenuSquare } from "react-icons/lu";
import { FaAngleDown, FaTriangleExclamation } from "react-icons/fa6";
import '../src/css/header.css';

const Header = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const [scrollPos, setScrollPos] = useState(0);
  const [scrollingUp, setScrollingUp] = useState(false);
  const [atTop, setAtTop] = useState(true);

  const targetRef = useRef(null);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const handleNavClick = (href) => {
    window.location.href = href;
  };

  const handleScroll = () => {
    const currentPosition = document.documentElement.scrollTop;
    setScrollingUp(currentPosition < scrollPos);
    setScrollPos(currentPosition);
    setAtTop(currentPosition === 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPos]);

  return (
    <nav
      className={`w-full sticky top-0 z-20 ${atTop ? "navbar expanded" : "navbar collapsed"}`}
    >
      <div className="flex justify-between items-center max-w-[1140px] mx-auto container px-4 sm:px-0">
        <NavLink
          to="/"
          onClick={() => {
            setActive("");
            window.scrollTo(0, 0);
          }}
        >
          <img src="/matilogo.png" className="z-40" alt="matisoftlogo" />
        </NavLink>
        <ul className="list-none hidden lg:flex flex-row items-center justify-between gap-x-1 fontUsed relative navbarRight">
          <li className="hover:text-secondary inline px-2 text-sm text-white cursor-pointer text-base" key="under-attack">
            <NavLink className="flex items-start gap-1" to="/ir-services" onClick={()=>window.scrollTo(0,0)}>
              <FaTriangleExclamation className="triangle" />
              Under Attack?
            </NavLink>
          </li>
          <li className="dropdown inline px-2 text-sm text-white cursor-pointer py-2" key="offerings">
            <a href="#" className="navIcon" data-toggle="dropdown">
              Offerings <FaAngleDown className="angle" />
            </a>
            <div className="dropdown-menu absolute hidden h-auto flex pt-8">
              <ul className="dropdown-menu block bg-primary px-5 py-[10px]">
                <li className="" key="ams">
                  <a className="block text-white-500 text-sm cursor-pointer" href="/ams">
                    Advanced Malware <br /> Scanner
                  </a>
                </li>
                <li className="" key="dlp">
                  <NavLink className="block text-white-500 text-sm cursor-pointer" to="/dlp" onClick={()=>window.scrollTo(0,0)}>
                    Data Leak Prevention
                  </NavLink>
                </li>
                <li className="" key="ir-services">
                  <NavLink className="block text-white-500 text-sm cursor-pointer" to="/ir-services" onClick={()=>window.scrollTo(0,0)}>
                    Incident Response <br /> Services
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li className="dropdown inline px-2 text-sm text-white cursor-pointer text-base" key="resources">
            <a className="navIcon text-md" href="#">
              Resources
              <FaAngleDown className="angle" />
            </a>
            <div className="dropdown-menu absolute hidden h-auto flex pt-8">
              <ul className="dropdown-menu block bg-primary px-5 py-[10px]">
                <li className="" key="case-studies">
                  <a className="block text-white-500 text-sm cursor-pointer" href="/case-studies">
                    Case Study
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className="dropdown inline px-2 text-sm text-white cursor-pointer text-base" key="about-us">
            <a className="navIcon text-md" href="#">
              About Us
              <FaAngleDown className="angle" />
            </a>
            <div className="dropdown-menu absolute hidden h-auto flex pt-8">
              <ul className="dropdown-menu block bg-primary px-5 py-[10px]">
                <li className="" key="customers">
                  <NavLink
                    className="block text-white-500 text-sm cursor-pointer"
                    to="#customers"
                    onClick={() => handleNavClick('/#customers')}
                  >
                    Our customers
                  </NavLink>
                </li>
                <li className="" key="partners">
                  <NavLink
                    className="block text-white-500 text-sm cursor-pointer"
                    to="#partners"
                    onClick={() => handleNavClick('/#partners')}
                  >
                    Our partners
                  </NavLink>
                </li>
                <li className="" key="awards">
                  <NavLink
                    className="block text-white-500 text-sm cursor-pointer"
                    to="#awards"
                    onClick={() => handleNavClick('/#awards')}
                  >
                    Awards
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          {/* <li key="contact">
            <a href="/contact" className="btn btn-default flex flex-row text-black">
              Contact an Expert
            </a>
          </li> */}
          <li><a href="/contact" className="btn btn-default flex flex-row text-black">Contact an Expert</a></li>
          {/* <li><NavLink to="/presignup" className="btn btn-default flex flex-row text-black">Start your free trial</NavLink></li> */}
        </ul>
        <div className="lg:hidden">
          <span onClick={() => setToggle(!toggle)}>
            <LuMenuSquare className="menuIcon" />
          </span>
          <div className={`${!toggle ? "hidden" : "navbar-small"} black-gradient mx-auto z-10 bg-primary delay-75`}>
            <ul className="list-none flex justify-end items-start flex-col gap-y-4">
              <li className="hover:text-secondary inline px-2 text-sm text-white cursor-pointer text-base" key="small-under-attack">
                <NavLink className="flex items-start gap-1" to="/ir-services">
                  <FaTriangleExclamation className="triangle" />
                  Under Attack?
                </NavLink>
              </li>
              <li className="dropdownSmall inline px-2 text-sm text-white cursor-pointer" key="small-offerings">
                <a className="navIcon" data-toggle="dropdown" onClick={handleOpen} href="#">
                  Offerings <FaAngleDown className="angle" />
                </a>
                {open && (
                  <div className="dropdown-menuSmall absolute hidden h-auto pt-8 w-full z-20">
                    <ul className="dropdown-menuSmall block bg-primary px-5 py-[10px]">
                      <li className="" key="small-ams">
                        <a className="block text-white-500 text-sm cursor-pointer" href="/ams">
                          Advanced Malware Scanner
                        </a>
                      </li>
                      <li className="" key="small-dlp">
                        <NavLink className="block text-white-500 text-sm cursor-pointer" to="/dlp">
                          Data Leak Prevention
                        </NavLink>
                      </li>
                      <li className="" key="small-ir-services">
                        <NavLink className="block text-white-500 text-sm cursor-pointer" to="/ir-services">
                          Incident Response Services
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              <li className="dropdownSmall inline px-2 text-sm text-white cursor-pointer text-base" key="small-about-us">
                <a className="navIcon text-md" onClick={handleOpen} href="#">
                  About Us
                  <FaAngleDown className="angle" />
                </a>
                {open && (
                  <div className="dropdown-menuSmall absolute hidden h-auto flex pt-8 w-full z-40">
                    <ul className="dropdown-menuSmall block bg-primary px-5 py-[10px]">
                      <li className="" key="small-customers">
                        <NavLink
                          to="/#customers"
                          className="block text-white-500 text-sm cursor-pointer"
                          onClick={() => {
                            setToggle(!toggle);
                            handleNavClick('/#customers');
                          }}
                        >
                          Our customers
                        </NavLink>
                      </li>
                      <li className="" key="small-partners">
                        <NavLink
                          to="/#partners"
                          className="block text-white-500 text-sm cursor-pointer"
                          onClick={() => {
                            setToggle(!toggle);
                            handleNavClick('/#partners');
                          }}
                        >
                          Our Partners
                        </NavLink>
                      </li>
                      <li className="" key="small-awards">
                        <NavLink
                          to="/#awards"
                          className="block text-white-500 text-sm cursor-pointer"
                          onClick={() => {
                            setToggle(!toggle);
                            handleNavClick('/#awards');
                          }}
                        >
                          Awards
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              <li className="dropdownSmall inline px-2 text-sm text-white cursor-pointer text-base" key="small-resources">
                <a className="navIcon text-md" onClick={handleOpen} href="#">
                  Resources
                  <FaAngleDown className="angle" />
                </a>
                {open && (
                  <div className="dropdown-menuSmall absolute hidden h-auto flex pt-8 w-full z-40">
                    <ul className="dropdown-menuSmall block bg-primary px-5 py-[10px]">
                      <li className="" key="small-case-studies">
                        <a className="block text-white-500 text-sm cursor-pointer" href="/case-studies">
                          Case Study
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              <li key="contact">
                <a href="/contact" className="btn btn-default flex flex-row text-center text-black min-w-[36vw]">
                  Contact an Expert
                </a>
              </li>
              {/* <li key="signup">
                <a href="/presignup" className="btn btn-default flex flex-row text-black min-w-[36vw]">
                  Start your free trial
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;