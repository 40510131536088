
import {
    REQUEST_DASHBOARD_SCAN,
    REQUEST_FAILED_DASHBOARD_SCAN,
    RECEIVE_DASHBOARD_SCAN,

} from './dashboard.action_type';

const initialState = {
    sortBy: 'createdAt', // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    // data: [],
    loadingInfected: false,
    scanDeviceCount: 0,
    noneScanDeviceCount: 0,
    totalRecords: 0,
    errorInfected: null,
    filters: {},
    pageSize: 100,
    pageNumber: 1,
    lastSortBy: null,
    lastSortOrder: null,
};

const reducer = (state = initialState, action) => {
    
    switch (action.type) {
        case REQUEST_DASHBOARD_SCAN:
            return {
                ...state,
                loadingInfected: true,
            };
        case RECEIVE_DASHBOARD_SCAN:
            return {
                ...state,
                loadingInfected: false,
                scanDeviceCount: action.payload.scanDeviceCount,
                noneScanDeviceCount: action.payload.noneScanDeviceCount,

                errorInfected: null,
            };
        case REQUEST_FAILED_DASHBOARD_SCAN:
            return {
                ...state,
                loadingInfected: false,
                errorInfected: action.payload,
            };

        default:
            return state;
    }
};

export { reducer };
