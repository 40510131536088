import React from "react";
import { styles } from "../styles";
import Slider from 'react-slick';
import CustomArrow from "../utils/Arrow";
const Feature = () => {
  const features = [
    {
      url:'/images/easy-installation (4).png',
      heading:"Easy to install and use",
      text:"The MC syncs with Active Directory for quick installation & easy management. Use USB drives or shared folders if not connected to AD/network"
    }
    ,{
      url:'/images/usb-drive (3).png',
      heading:"Compatible with any usb drive",
      text:"Works with any USB drive available in the market",

    }
    ,{
      url:'/images/audit.png',
      heading:"Detailed Logs",
      text:"Logs of all files transferred in & out of any computer can be viewed centrally on the MC for 180 days for compliance with CERT-In"
    },
    { url:'/images/compliant (1).png',
    heading:"Compliance",
    text:"Compliant with all security guidelines like CERT-In, RBI, GDPR, HIPAA, NISPG"
  },
    { 
      url:'/images/encrypted (1).png',
    heading:"Data Protection",
    text:"Restrict file sharing within departments like the director's office, OT, marketing and sales"
  },
  { url:'/images/windows-logo-silhouette.png',
    heading:"OS Support",
    text:"Works on Windows 7 Sp1 & Windows Server 2012 R2 SP1 or later"
  }
  ];
 
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000, 
    // fade: true,
    fade:false,
    centerMode: true,
    centerPadding: '0',
    easing: 'linear',
   
    nextArrow: <CustomArrow color="skyblue" direction="next" />,
    prevArrow: <CustomArrow color="skyblue" direction="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  
  return (
    <div className=" w-full h-screen xl:h-screen mx-auto rounded-b-[10px] bg-opacity-20 
      bg-[#fff]  py-[9vh]
    " id="dlpFeatures" >
      <div
        className={`inset-0  container mx-auto ${styles.paddingX} ` }
      >
        <div className="featureHeading">
        <h1 className="text-secondary text-4xl font-extrabold font-heading  mx-auto py-[5vh]  font-high ">Standard Features</h1></div>
        <p className="text-primary  py-2  font-medium  mx-auto text-justify">
        Matisoft DLP is controlled centrally via its on-premise Management Console (MC) that is installed on a computer/server within your organization
        </p>
       
          <div className="mx-auto">
         <Slider {...settings} className='mx-auto  '>
         
        {features.map((feature, index) => (
        <div className="mx-auto ">
          <div key={index} 
        
          className='featureBox mx-auto px-1'
          >
            <img src={feature.url} alt={`Slide ${index + 1}`} className=' mx-auto w-1/3  object-contain py-5'/>
            {/* <p className={`display:${index===4?'block':'hidden'}`}>Microsoft</p> */}
            {/* {index===1 && <p className='text-center my-[-40px] py-0'>Microsoft Partner Startup</p>} */}
            <div>
              <h1 className="text-center py-3 text-xl font-semibold text-[#4fd0ff]">{feature.heading}</h1>
              <p className="  text-justify  ">{feature.text}</p>
            </div>
          </div></div>
        ))}
     
      </Slider></div>
      </div>
    </div>
  );
};


export default Feature;
