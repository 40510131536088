import { RECEIVE_ALL_COMPANY, REQUEST_FAILED_ALL_COMPANY, REQUEST_ALL_COMPANY, SET_FILTER_ALL_COMPANY, SET_PAGE_NUMBER_ALL_COMPANY, SET_PAGE_SIZE_ALL_COMPANY, SET_SORT_OPTIONS_ALL_COMPANY } from "./allCompany.action_type"

const initialState = {
    loading: false,

    sortBy: 'createdAt', // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    loading: false,
    allCompaniesList: [],
    org_name: '',
    totalRecords: 0,
    error: null,
    filters: {},
    pageSize: 100,
    pageNumber: 1,
    error: false,

}
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case REQUEST_ALL_COMPANY:
            return {
                ...state,
                loading: true,
            }
        case RECEIVE_ALL_COMPANY:
            return {
                ...state,
                loading: false,
                allCompaniesList: action?.payload?.data,
                totalRecords: action.payload.totalRecords,
                org_name: action.payload.org_name
            }
        case REQUEST_FAILED_ALL_COMPANY:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case SET_SORT_OPTIONS_ALL_COMPANY:
            return {
                ...state,
                lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
                lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
                sortBy: action.payload.sortBy,
                sortOrder: action.payload.sortOrder,
            };
        case SET_PAGE_SIZE_ALL_COMPANY:
            return {
                ...state,
                pageSize: action.payload,
            };
        case SET_PAGE_NUMBER_ALL_COMPANY:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_FILTER_ALL_COMPANY:
            return {
                ...state,
                filters: action.payload,
            }

        default: return state;
    }
}
export { reducer };