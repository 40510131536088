import { SET_RELEASE, ERROR_RELEASE, REQUEST_RELEASE } from "./release.action_type";
import axios from "axios";
import { setPermissions } from "../companyid/companyid.action";
import { toast } from "react-toastify";

export const checkRelease = (userId, id, path) => {
  try {
    return async (dispatch) => {
      dispatch({ type: REQUEST_RELEASE });

      try {
        // Prepare the payload based on the presence of id
        const payload = id ? { userId: userId, directoryId: id } : { userId: userId, path: path };

        const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/release`, payload);

        dispatch({ type: SET_RELEASE, payload: response });

        // Uncomment this block if you need to handle permissions
        // if (response?.data?.permissions) {
        //   dispatch(setPermissions(response.data.permissions));
        // }
        // console.log('Data from backend FOR RELEASE:', response);
        // Handle the response data
      } catch (error) {
        console.error('Error fetching data:', error);
        dispatch({ type: ERROR_RELEASE, payload: error });
        toast.error(error?.response?.data || error?.message);
        // Handle errors
      }
    };

  } catch (error) {
    console.log(error, 'edrfdfdsfdsd');
  }
};
