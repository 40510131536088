const styles = {
    paddingX: "sm:px-4 px-2",
    paddingY: "sm:py-4 py-6",
    padding: "sm:px-16 px-6 sm:py-16 py-10",

    heroHeadText:
    "font-black  lg:text-[30px] sm:text-[22px] xs:text-[24px] text-[40px]  mt-2", 
    heroSubText:
    "  lg:text-[15px] sm:text-[13px] xs:text-[11px] text-[16px] ",
    sectionHeadText:
    " upperCase md:text-[22px] sm:text-[20px] xs:text-[18px] text-[30px]",
  sectionSubText:
    "sm:text-[16px] text-[13px]  ",
    sectionText: "text-xs md:text-sm 2xl:text-xl",
            navlinkActive:"pointer text-secondary scale-110 ",
    navinkHover :"pointer text-secondary transform" ,
    roundDot: "w-4 h-4 rounded-full bg-primary",
    flexY: "flex flex-col items-center text-white rounded-lg text-lg",
    color: "sm:bg-yellow-300 text-20px]  lg:bg-pink-400 text-[80px] "
}
    export {styles}