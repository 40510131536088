import React from 'react'
import { styles } from '../styles'
import Slider from "react-slick";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import CustomArrow from '../utils/Arrow';
const CaseStudyIr = () => {
  const caseStudies=[
    {name:"ShadowPad",heading:"Discovered in 2017, originated from multiple groups like APT41, Barium, Winnti, etc. ",subHeading:"Primary targets are Critical Organisations of the country like energy & power sector, healthcare, telecoms, transportation, etc.",url:""},
    {name:"PlugX",heading:"PlugX is a RAT associated with cyber espionage in North Asia.",subHeading:"PlugX targets government, defense, tech, finance, and energy for espionage and data theft, posing a significant threat with operational disruptions, breaches, and reputational risks.",url:""},
    {name:"Stuxnet",heading:"Speculated to be released by U.S. & Israel, it disrupted operations in Iran’s nuclear power plant",subHeading:"Stuxnet is an extremely sophisticated computer worm that exploited multiple previously unknown Windows zero-day vulnerabilities to infect computers and spread.",url:""},
    {name:"WMI Ghost",heading:"Origin by Thrip apt group in 2013",subHeading:"Targets entities in Southeast Asia, including military, defense, telecom companies, geospatial imaging systems, satellite communications, media, and educational organizations.",url:""},
    {name:"DJVU Ransomware",heading:"First emerged around 2018 and quickly became widespread.",subHeading:"Djvu ransomware (STOP) globally impacts users and organizations, causing data loss and financial harm while constantly evolving to evade detection.",url:""},
    {name:"APT29",heading:"Created by The Dukes, a Russian cyberespionage group, may have ties to the Russian Foreign Intelligence Service",subHeading:"Primarily targeted Western governments and related organizations, such as government ministries and agencies, political think tanks, and governmental subcontractors.",url:""},
    {name:"Cobalt Kitty",heading:"Carried out by Vietnamese hacking group OceanLotus was a large-scale APT that targeted global corporations based in Asia",subHeading:"The goal was to steal proprietary business information by targeting company’s top-level management using sophisticated spear-phishing.",url:""},
    {name:"SAMSAM",heading:"Speculated to be created in Iran, created havoc in the US healthcare industry",subHeading:"Used majorly brute-force tactics to login & then encrypted files across the organisation’s network before making a ransom demand",url:""},
   
   
  ]
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:true,
    autoplay: true,
    autoplaySpeed: 4000, 
    // fade: true,
    fade:false,
    centerMode: true,
    centerPadding: '0',
    easing: 'linear',
    nextArrow: <CustomArrow color="skyblue" direction="next" />,
    prevArrow: <CustomArrow color="skyblue" direction="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="h-screen  w-full  mx-auto py-[7vh] mb-[3vh]" id="casestudy">
    <div className={`container ${styles.paddingX} mx-auto  `}>
      <h2 className="font-heroHeading text-primary text-center mx-auto py-[4vh]  ">
     Case Studies
      </h2>
      <div className='py-[4vh]'>
      <Slider {...settings} className="gap-1  ">
        {caseStudies.map((item,index)=>(
   <div className='caseStudy h-[54vh]'>
    <a href={`${index===2 ? '/case-studies/stuxnet' : index===5?'/case-studies/apt29':index===6?'/case-studies/cobaltkitty':index===7?'/case-studies/samsam':'#casestudy'} `}>
          <div key={index} className={`caseStudyIr-container mx-auto relative  ${index===0 ? 'caseShadowPad':index===1?"casePlugx":index===2?'caseStuxnet':index===3? 'caseWMI':index===4?'caseRansomware':index===5?'caseAPT':index===6?'caseCobaltKitty':'caseSamsam'} w-[95%] px-1 gap-x-1`} >
        <h2 className='text-white top-0 font-aboveMed text-center relative'>{item.name}</h2>
        <h3 className=' absolute bottom-0 left-2 right-2   text-white text-justify '>{item.heading}</h3>
        <h4 className='text-white absolute bottom-0 px-2 '>{item.subHeading}</h4>
        </div> </a> </div>
        
        ))}
       
        </Slider>
      </div>
        </div>
    </div>
  )
}


export default CaseStudyIr;