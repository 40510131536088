
import { REQUEST_LICENCE_LINKED_DEVICES,  SET_FILTER_LICENCE_LINKED_DEVICES, SET_PAGE_NUMBER_LICENCE_LINKED_DEVICES, SET_PAGE_SIZE_LICENCE_LINKED_DEVICES, SET_SORT_OPTIONS_LICENCE_LINKED_DEVICES, RECEIVE_LICENCE_LINKED_DEVICES, REQUEST_FAILED_LICENCE_LINKED_DEVICES } from "./licence.action_type"
const initialState = {
    loading: false,

    sortBy: 'license_usedate', // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    // data: [],
    loading: false,
    linkedDevices: [],
    totalRecords: 0,
    error: null,
    filters: {},
    // actionList:[],
    pageSize: 100,
    pageNumber: 1,
    error: false,

}
const reducer = (state = initialState, action) => {
// console.log(state,"linked devices listing");
// console.log(state.linkedDevices,"linked devices listing");

    switch (action.type) {
        case REQUEST_LICENCE_LINKED_DEVICES:
            return {
                ...state,
                loading: true,
            }
        case RECEIVE_LICENCE_LINKED_DEVICES:
            return {
                ...state,
                loading: false,
                // actionList:action.payload.statusList,
                linkedDevices: action.payload.data,
                totalRecords:action.payload.totalRecords,
            }
        case REQUEST_FAILED_LICENCE_LINKED_DEVICES:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case SET_SORT_OPTIONS_LICENCE_LINKED_DEVICES:
            return {
                ...state,
                lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
                lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
                sortBy: action.payload.sortBy,
                sortOrder: action.payload.sortOrder,
            };
        case SET_PAGE_SIZE_LICENCE_LINKED_DEVICES:
            return {
                ...state,
                pageSize: action.payload,
            };
        case SET_PAGE_NUMBER_LICENCE_LINKED_DEVICES:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_FILTER_LICENCE_LINKED_DEVICES: {
            return {
                ...state,
                filters: action.payload,
            }
        }
        default: return state;
    }
}
export { reducer };