import React from 'react';

const ContextMenu = ({ position, onClose, onSelect }) => {
    return (
        <div
            className="absolute top-0 left-0  z-40 bg-white border border-gray-300 rounded shadow-lg"
            style={{ top: position.y, left: position.x }}
            onMouseLeave={onClose} // Close on mouse leave
        >
            <ul className="list-none m-0 p-2">
                <li
                    className=" py-1 px-2 hover:bg-gray-200 cursor-pointer "
                    onClick={() => {
                        onSelect(); // Handle selecting the file/folder
                        onClose(); // Close the menu
                    }}
                >
                   Details
                </li>
                {/* Add more options here if needed */}
            </ul>
        </div>
    );
};

export default ContextMenu;
