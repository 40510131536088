import React from "react";

import Customers from "./components/Customers";
import Award from "./components/Award";
import Partner from "./components/Partner";
import HeroHome from "./components/HeroHome";
// import TrialSec from "./components/TrialSec";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from "./Footer";
import Header from "./Header1";
import CookiesBanner from "./components/Cookie";
import HeroHome1 from "./components/HeroHome1";
import { Helmet } from "react-helmet";
const Home = () => {
  return (
    <>
     <Helmet>
        <title>Matisoft website</title>
      </Helmet>
   <Header/>
   {/* <HeroHome/> */}
   <HeroHome1 />
   <Customers />
   <Award/>
   <Partner/>
   <CookiesBanner/>
   <Footer/>
    </>
  );
};

export default Home;
