import { createSelector } from 'reselect';

// Input selector
const getCompanyIdState = (state) => state.companyid;

// Memoized selectors
export const selectCompanyId = createSelector(
  [getCompanyIdState],
  (companyid) => companyid.companyId
);

export const selectFileId = createSelector(
  [getCompanyIdState],
  (companyid) => companyid.fileId
);

export const selectUserId = createSelector(
  [getCompanyIdState],
  (companyid) => companyid.userId
);

export const selectInstallerId = createSelector(
  [getCompanyIdState],
  (companyid) => companyid.insatllerId
);

export const selectComputerName = createSelector(
  [getCompanyIdState],
  (companyid) => companyid.computerName
);

export const selectCustomerName = createSelector(
  [getCompanyIdState],
  (companyid) => companyid.customerName
);

// Example of combining multiple selectors
export const selectCompanyAndUserId = createSelector(
  [selectCompanyId, selectUserId],
  (companyId, userId) => ({
    companyId,
    userId
  })
);
