import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import SidebarLicense from '../../common/SidebarLicense';
import { useDispatch, useSelector } from 'react-redux';
import { setUserId } from '../../../Redux/companyid/companyid.action';
import { checkRelease } from '../../../Redux/release/release.action';
import { IoMdMenu } from "react-icons/io";
import { FaArrowLeft, FaArrowRight, FaEdit } from "react-icons/fa";
import { LuDelete, LuRefreshCcw } from 'react-icons/lu';
import { RiFileAddLine } from "react-icons/ri";
import { FaFolderPlus } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { ImCross } from 'react-icons/im';
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { RxCross2 } from "react-icons/rx";
import 'react-tabs/style/react-tabs.css'; // Import default styles for react-tabs
import { FaFolderClosed } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { ImFileText2 } from "react-icons/im";
import { FiFolderPlus } from "react-icons/fi";
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams and useNavigate
import ContextMenu from '../../components/ContextMenu';
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from 'react-toastify';
import axios from 'axios';
import Loader from '../../common/Loader';
import { FaFolder } from "react-icons/fa";
import SidebarRelease from '../../common/SidebarRelease';
import { IoMdArrowUp } from "react-icons/io";
import { MdOutlineErrorOutline } from "react-icons/md";

// import { FaFileAlt } from "react-icons/fa";

// import Loader from '../../common/Loader'

const Release = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // For navigation
    const { path: currentPath = "" } = useParams(); // Get the current path from the URL
    const [path, setPath] = useState(currentPath); // Set initial path based on the URL

    const { userId, releaseData, loading } = useSelector((state) => ({
        userId: state.companyid.userId,
        releaseData: state.release.releaseData,
        loading: state.release.loading
    }));
    // console.log(releaseData, "release data in main file");
    // const [viewwdData]
    const [tempData, setTempData] = useState([]);
    const [updateData, setUpdateData] = useState(false);
    const [isSidebarExpanded, setSidebarExpanded] = useState(true);
    const [isPreviewVisible, setPreviewVisible] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [activeTab, setActiveTab] = useState('tab1');
    const [folderWindow, setFolderWindow] = useState(false);
    const [fileWindow, setFileWindow] = useState(false);
    const [editWindow, setEditWindow] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [showConfirmationModal, setShowConfirmationModal] = useState(null)
    const [showConfirmFirst, setShowConfirmFirst] = useState(null)

    const [newFolder, setNewFolder] = useState({
        directoryName: '',
        cpuArchitecture: '',
        isLive: '',
        description: '',
        type: "folder"
    });

    const [newFile, setNewFile] = useState({
        file: null,
        type: 'file'
    });

    const [updateFileOrFolder, setUpdateFileOrFolder] = useState({
        directoryName: '',
        isLive: '',
        description: '',
    });

    const toggleSidebar = () => {
        setSidebarExpanded(!isSidebarExpanded);
    };

    const handleFileClick = (file) => {
        setSelectedFile(file);
        setPreviewVisible(true);
    };

    const handleClosePreview = () => {
        setPreviewVisible(false);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleEdit = (e, formType, field) => {
        const value = e.target.value;
        switch (formType) {
            case 'newFile':
                const file = e.target.files[0]; // Get the first file
                if (file) {
                    setNewFile({ file, type: 'file' }); // Set the file and its type
                }
                break;
            case 'newFolder':
                setNewFolder({ ...newFolder, [field]: value });
                break;
            case 'updateFileOrFolder':
                setUpdateFileOrFolder({ ...updateFileOrFolder, [field]: value });
                break;
            default:
                break;
        }
    };
    const handleEditPrefill = () => {

        setUpdateFileOrFolder((prevState) => ({
            ...prevState,
            directoryName: selectedFile?.name || '',
            isLive: selectedFile?.isLive || 'No', // Default to 'No' if not set
            description: selectedFile?.description || '', // Retain previous description if available
        }));

    };

    const toggleEdit = () => {
        handleEditPrefill();
        setEditWindow(!editWindow);
    }
    const [fileUploadRequest, setFileUploadRequest] = useState(false);

    const handleFileSubmit = (e) => {
        e.preventDefault();
        if (!newFile.file) {
            toast.error("Please select a file to upload."); // Alert if no file is selected
            return;
        }

        const formData = new FormData(); // Create a new FormData object
        formData.append('userId', userId);
        formData.append('file', newFile.file); // Append the file
        formData.append('directoryId', releaseData?._id);
        formData.append('path', releaseData?.path);
        formData.append('architecture', releaseData?.architecture); // Add other metadata if needed

        setFileUploadRequest(true); // Set to true before API call

        // API call for uploading the file
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/release/upload/file`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Set correct content type
            },
        })
            .then((response) => {
                toast.success(response?.data?.msg);
                // Create a new array with the existing tempData and the new file
                setTempData((prevData) => [...(prevData || []), { ...newFile, file: newFile.file.name }]); // Add the filename for display
                setFileWindow(false);
                setUpdateData(!updateData);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error?.response?.data?.error || error?.message);
            })
            .finally(() => {
                setFileUploadRequest(false); // Reset to false once API call is complete
            });
    };


    const handleFolderSubmit = (e) => {
        e.preventDefault();
        // API call for editing metadata
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/release/add/dir`, {
            userId: userId,
            directoryId: releaseData?._id,
            dirName: newFolder.directoryName,
            path: releaseData?.path,
            isLive: newFolder.isLive === "Yes" ? true : newFolder.isLive === "No" ? false : '',
            description: newFolder.description,
            architecture: newFolder.cpuArchitecture
        })
            .then((response) => {
                setUpdateData(!updateData);

                toast.success(response?.data?.msg)
            })
            .catch((error) => {
                console.log(error);
                toast.error(error?.response?.data?.error || error?.message);
            })
        // Create a new array with the existing tempData and the new folder
        setTempData((prevData) => [...(prevData || []), newFolder]); // Use prevData safely
        setFolderWindow(false);
    };

    const handleEditSubmit = (e) => {
        e.preventDefault();
        // API call for editing metadata
        axios.post(`${process.env.REACT_APP_HOST_NAME}/api/release/update/dirmeta`, {
            "userId": userId,
            "directoryId": selectedFile?._id,
            "path": selectedFile?.path,
            "name": updateFileOrFolder?.directoryName,
            "description": updateFileOrFolder?.description,
            "isLive": updateFileOrFolder?.isLive === "Yes" ? true : updateFileOrFolder?.isLive === "No" ? false : '',
        })
            .then((response) => {
                toast.success(response?.data?.msg);
                setUpdateData(!updateData);

            })
            .catch((error) => {
                console.log(error);
                toast.error(error?.response?.data?.error || error?.message);
            })
        setEditWindow(false);
    };
    const handleDelete = () => {
        if (selectedFile?.type === 'file') {
            axios.post(`${process.env.REACT_APP_HOST_NAME}/api/release/delete/file`, {
                userId: userId,
                fileId: selectedFile?._id,
                path: selectedFile?.path
            })
                .then((response) => {
                    toast.success(response?.data);
                    setShowConfirmationModal(null);
                    setUpdateData(!updateData);
                    setPreviewVisible(false);

                })
                .catch((error) => {
                    console.log(error);

                    toast.error(error?.response?.data?.error || error?.message);
                });
        } else if (selectedFile?.type === 'directory') {
            axios.post(`${process.env.REACT_APP_HOST_NAME}/api/release/delete/dir`, {
                userId: userId,
                directoryId: selectedFile?._id,
                path: selectedFile?.path
            })
                .then((response) => {
                    toast.success(response?.data);
                    setShowConfirmationModal(null);
                    setPreviewVisible(false);
                    setUpdateData(!updateData);
                })
                .catch((error) => {
                    console.log(error);

                    toast.error(error?.response?.data?.error || error?.message);
                });
        }
    };

    const handleDeleteConfirmFirst = () => {
        setShowConfirmFirst(null);
        setShowConfirmationModal('delete');

    }
    const searchParams = new URLSearchParams(window.location.search);
    const directoryId = searchParams.get('directoryId') || ''; // Retrieve 'directoryId'
    useEffect(() => {
        // Get the current URL without query parameters
        const currentURL = window.location.pathname.split('?')[0]; // Remove any query params

        // Split the path to get the 'userid'
        const parts = currentURL.split('/');
        const userid = parts[parts.length - 1]; // Assuming the userid is the last part of the URL

        dispatch(setUserId(userid));

        if (userid) {
            // Pass the 'directoryId' to checkRelease
            dispatch(checkRelease(userid, directoryId));
        }
    }, [dispatch, updateData, directoryId]);
    const [subDirectory, setSubDirectory] = useState({})

    const toGetsubDirectory = () => {
        // axios.post(`${process.env.REACT_APP_HOST_NAME}/api/release/dir/count`, {
        //     "userId": userId,
        //     "directoryId": selectedFile?._id
        // }).then((response) => {
        //     setSubDirectory(response?.data);
        //     console.log(response, "sub child");

        // }).catch((error) => {

        // })
    }

    useEffect(() => {
        if (selectedFile && selectedFile.type !== 'file') {
            axios.post(`${process.env.REACT_APP_HOST_NAME}/api/release/dir/count`, {
                "userId": userId,
                "directoryId": selectedFile._id
            })
                .then((response) => {
                    setSubDirectory(response?.data);
                })
                .catch((error) => {
                    console.error("Error fetching subdirectory data:", error);
                });
        }
    }, [selectedFile]);

    const handleSingleClick = (item) => {
        setSelectedFile(item);
        toGetsubDirectory();
    }
    // Function to navigate into a folder
    const handleFolderDoubleClick = (folder) => {

        // Get the current URLSearchParams
        const searchParams = new URLSearchParams(window.location.search);

        // Check if the selected file has _id and update the 'directoryId' query param
        if (selectedFile && selectedFile._id) {
            searchParams.set('directoryId', selectedFile._id); // Set or replace 'directoryId'
        }
        setSelectedFile(null)
        setPreviewVisible(false);

        // Navigate to the updated URL with only the 'directoryId' query param
        navigate(`/cms/release/manage/${userId}?${searchParams.toString()}`);
        toGetsubDirectory();
        // Update the local state (if needed)
        setUpdateData(!updateData);
    };

    const handleBack = () => {
        // Get the current URLSearchParams
        const searchParams = new URLSearchParams(window.location.search);

        // Check if parentDirectory exists and set or remove 'directoryId'
        if (releaseData && releaseData._id) {
            if (releaseData.parentDirectory) {
                searchParams.set('directoryId', releaseData.parentDirectory); // Set 'directoryId'
            } else {
                searchParams.delete('directoryId'); // Remove 'directoryId' if parentDirectory is null
            }
        }
        setSelectedFile(null)
        setPreviewVisible(false);
        // Update the URL with the modified query parameters
        navigate(`/cms/release/manage/${userId}?${searchParams.toString()}`);

        // Trigger any state update if necessary
        setUpdateData(!updateData);
    };

    const handleForward = () => {

        if (selectedFile?.type === 'directory') {
            // console.log("ghgh");

            handleFolderDoubleClick();
        }
    };

    const [contextMenuPosition, setContextMenuPosition] = useState(null);
    const [contextMenuVisible, setContextMenuVisible] = useState(false);

    const handleFileRightClick = (file, event) => {
        event.preventDefault(); // Prevent the default right-click menu
        setSelectedFile(file); // Set the selected file

        const menuWidth = 200; // Approximate menu width (adjust to actual menu width)
        const menuHeight = 100; // Approximate menu height (adjust to actual menu height)
        const offsetX = -220; // Minor horizontal offset from cursor
        const offsetY = -140; // Minor vertical offset from cursor

        // Calculate initial position near cursor
        let x = event.pageX + (isSidebarExpanded ? offsetX : -100);
        let y = event.pageY + offsetY;

        // Adjust to prevent overflow
        if (x + menuWidth > window.innerWidth) {
            x = window.innerWidth - menuWidth - offsetX;
        }
        if (y + menuHeight > window.innerHeight) {
            y = window.innerHeight - menuHeight - offsetY;
        }

        setContextMenuPosition({ x, y });
        setContextMenuVisible(true);
    };


    const handleContextMenuClose = () => {
        setContextMenuVisible(false); // Hide context menu
    };

    const handlePropertiesSelect = () => {
        setPreviewVisible(true); // Open the preview
    };
    const [clickedPath, setClickedPath] = useState(null);

    const handlePathClick = (partialPath) => {
        dispatch(checkRelease(userId, null, partialPath));
        setClickedPath(partialPath); // Store the clicked path to trigger navigation
        setPreviewVisible(false);
    };

    useEffect(() => {
        if (clickedPath && releaseData && releaseData._id) {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('directoryId', releaseData._id); // Update 'directoryId'

            // Navigate to the new URL
            navigate(`/cms/release/manage/${userId}?${searchParams.toString()}`);

            // Reset clickedPath after navigation
            setClickedPath(null);
        }
    }, [releaseData]);

    if (loading) return <Loader />;

    return (

        <div className="flex h-screen">
            <Helmet>
                <title>Matisoft | Release</title>
            </Helmet>

            {/* Sidebar */}
            <div className={`${isSidebarExpanded ? 'w-1/5' : 'w-1/25'} bg-gray-200 h-full z-[42] fixed`}>
                <SidebarRelease isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />
            </div>

            {/* Main Content */}
            <div className={`flex-1 max-w-full ${isSidebarExpanded ? 'ml-[20%]' : 'ml-[4%]'}  relative overflow-hidden 2xl:text-[1.2em] `} >
                <div style={{ fontWeight: '300' }} className="flex flex-row justify-between !font-[300] pt-[5vh] px-[1vw] text-[1em]">

                    {/* <div className="flex flex-wrap items-center">
                        Release {releaseData?.path && releaseData.path.split('/').map((folder, index) => (
                            <span className='text-gray-900' key={index}>
                                {index > 0 && <>&nbsp; &nbsp;&gt; &nbsp;&nbsp;</>}
                                {folder}
                            </span>
                        ))}
                    </div> */}

                    <div className="flex flex-wrap items-center">
                        <span
                            className="text-gray-900 cursor-pointer"
                            onClick={() => handlePathClick('/')}
                        >
                            <span className="hover:text-blue-500">Release</span>
                        </span>
                        {releaseData?.path && releaseData.path.split('/').map((folder, index, arr) => (
                            <span
                                className="text-gray-900 cursor-pointer"
                                key={index}
                                onClick={() => handlePathClick(arr.slice(0, index + 1).join('/'))}
                            >
                                {index > 0 && <>&nbsp;&gt;&nbsp;</>}
                                <span className="hover:text-blue-500">{folder}</span>
                            </span>
                        ))}
                    </div>

                    <div className="flex flex-row items-center gap-x-1 cursor-pointer" onClick={() => setPreviewVisible(true)}>
                        <IoMdMenu /> Details
                    </div>
                </div>

                {/* Main Area with tempData */}
                <div className={`px-[1vw] pt-[2vw] relative h-full   `}>
                    {/* <div className={`px-[1vw] pt-[2vw] relative h-full  `}> */}

                    <div className="flex flex-row items-center gap-x-[2.2vw] font-md text-[1.3em] pb-[1.6vh]">

                        {/* Navigation and actions */}
                        <IoMdArrowUp title='Go to parent directory' className='cursor-pointer hover:text-blue-600' onClick={handleBack} />
                        {/* <IoMdArrowForward title='Forward' className='cursor-pointer hover:text-blue-600'
                            onClick={handleForward}
                        /> */}
                        <RiFileAddLine
                            title={releaseData?.isArchive ? 'Cannot add files to an archived folder' : 'Add File'}
                            onClick={() => {
                                if (!releaseData?.isArchive) {
                                    setFileWindow(true);
                                }
                            }}
                            className={`${releaseData?.isArchive
                                ? 'cursor-not-allowed opacity-50'
                                : 'cursor-pointer hover:text-blue-600'
                                }`}
                        />

                        <FiFolderPlus
                            title={releaseData?.isArchive ? 'Cannot add folders to an archived folder' : 'Add Folder'}
                            onClick={() => {
                                if (!releaseData?.isArchive) {
                                    setFolderWindow(true);
                                }
                            }}
                            className={`${releaseData?.isArchive
                                ? 'cursor-not-allowed opacity-50'
                                : 'cursor-pointer hover:text-blue-600'
                                }`}
                        />

                        <FiEdit
                            title={
                                !selectedFile
                                    ? 'Select a folder first'
                                    : selectedFile?.isArchive
                                        ? 'Archive file/folders can not be edited'
                                        : 'Edit'
                            }
                            className={`${!selectedFile || selectedFile?.isArchive || selectedFile.type === 'file'
                                ? 'cursor-not-allowed opacity-50'
                                : 'hover:text-blue-600 cursor-pointer'
                                }`}
                            onClick={() => {
                                if (selectedFile && !selectedFile?.isArchive && selectedFile.type !== 'file') {
                                    toggleEdit();
                                }
                            }}
                        />

                        <RiDeleteBin6Line
                            title={
                                !selectedFile
                                    ? 'Select a file or folder first'
                                    : selectedFile?.isArchive
                                        ? 'Archive file/folder cannot be deleted'
                                        : selectedFile?.type === 'file'
                                            ? 'Delete'
                                            : selectedFile?.type === 'directory' && subDirectory?.sub_directory_count === 0 && subDirectory?.file_count === 0
                                                ? 'Delete'
                                                : 'Only empty folders can be deleted'
                            }
                            className={`${selectedFile && !selectedFile?.isArchive && (
                                (selectedFile?.type === 'file') || // If it's a file, enable delete
                                (selectedFile?.type === 'directory' && subDirectory?.file_count === 0 && subDirectory?.sub_directory_count === 0) // If it's a directory, check conditions
                            )
                                ? 'hover:text-blue-600 cursor-pointer' // Enable delete button styling
                                : 'cursor-not-allowed opacity-50' // Disable delete button styling
                                }`}
                            onClick={() => {
                                if (
                                    selectedFile &&
                                    !selectedFile?.isArchive &&

                                    (selectedFile?.type === 'file' ||
                                        (selectedFile?.type === 'directory' && subDirectory?.file_count === 0 && subDirectory?.sub_directory_count === 0))
                                ) {
                                    setShowConfirmFirst('delete');
                                }
                               
                            }}
                        />

                        <LuRefreshCcw title='Reload' className='cursor-pointer hover:text-blue-600' onClick={() => setUpdateData(!updateData)} />

                        {releaseData && (

                            <>
                                <span className="text-[0.7em]">Folder Architecture: {releaseData?.architecture}</span> |
                                <span className="text-[0.7em]">
                                    Folder Status:
                                    <span className='font-[800]'
                                        style={{
                                            color: releaseData?.isArchive ? '#495057' : releaseData?.isLive ? '#00a3b7' : '#F59E0B',
                                            fontWeight: '!bold'
                                        }}
                                    >
                                        {releaseData?.isArchive ? " Archive" : releaseData?.isLive ? " Live" : " Non Live"}
                                    </span>
                                </span>
                            </>
                        )}

                    </div>

                    {fileWindow && (
                        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-95 z-50">
                            <div className="bg-[#5090ad] border-white border-1 rounded-lg shadow-lg w-[42vw] max-h-[95vh] p-4 overflow-y-auto">
                                <div className="relative py-[1vh]">
                                    <button className="absolute right-[-10px] top-[-10px]" onClick={() => setFileWindow(false)}>
                                        <ImCross className="text-white bg-red-600 p-1 text-xl font-[200] rounded-full" />
                                    </button>
                                    <form onSubmit = {(e) => {
                                        if (!fileUploadRequest) handleFileSubmit(e);
                                    }}>
                                        <h1 className="text-[1.7em] pb-[4vh] text-center font-semibold text-white brightness-200 ">
                                            Upload File
                                        </h1>
                                        <div className="flex flex-row py-[2vh] items-center justify-between">
                                            <label className="block mb-2 text-white text-[1.2em] font-[500] ">Choose file to upload*</label>
                                            <input type="file" className="border text-gray-100 p-2 w-[22vw]" required onChange={(e) => handleEdit(e, 'newFile', 'file')} />
                                        </div>
                                        <div className="flex justify-end mt-4 gap-x-1">
                                            <button disabled={fileUploadRequest === true} className="border bg-primary border-primary text-white px-4 py-2 rounded hover:bg-transparent hover:text-primary">
                                                Upload
                                            </button>
                                            <button type="button" className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300" onClick={() => setFileWindow(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Edit Modal */}
                    {editWindow && selectedFile && (
                        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-90 z-50">
                            <div className="bg-[#5090ad] border border-white rounded-lg shadow-lg w-[44vw] max-h-[90vh] p-6 overflow-y-auto">
                                <div className="relative">
                                    {/* Close Button */}
                                    <button className="absolute right-[-15px] top-[-15px]" onClick={() => setEditWindow(false)}>
                                        <ImCross className="text-white bg-red-600 p-1 text-xl rounded-full hover:bg-red-700" />
                                    </button>

                                    {/* Form */}
                                    <form onSubmit={handleEditSubmit}>
                                        <h1 className="text-[1.6em] pb-4 text-center font-semibold text-white brightness-200 ">
                                            Edit Directory Metadata
                                        </h1>

                                        {/* Directory Name Field */}
                                        <div className="mb-6 flex flex-row items-center">
                                            <label className="block text-lg text-white w-[18vw] brightness-200 ">
                                                Directory Name*
                                            </label>
                                            <input
                                                type="text" placeholder='Enter Directory Name'
                                                className="border p-3 w-[28vw] rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                onChange={(e) => handleEdit(e, 'updateFileOrFolder', 'directoryName')}
                                                value={updateFileOrFolder.directoryName || ''}
                                                required
                                                maxLength='100'
                                            />
                                        </div>

                                        {/* Is Live Select */}
                                        <div className="mb-6 flex flex-row items-center">
                                            <label className="block text-lg text-white w-[18vw] brightness-200">
                                                Is Live*
                                            </label>
                                            <select
                                                className="border p-3 w-[28vw] rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                onChange={(e) => handleEdit(e, 'updateFileOrFolder', 'isLive')}
                                                value={updateFileOrFolder.isLive || 'No'}
                                                defaultValue=""
                                            >
                                                <option disabled value="">Make folder live</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>

                                        {/* Description Field */}
                                        <div className="mb-6 flex flex-row items-center">
                                            <label className="block text-lg text-white w-[18vw] brightness-200">
                                                Description (Optional)
                                            </label>
                                            <textarea
                                                className="border p-3 w-[28vw] rounded focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                                                rows="4"
                                                value={updateFileOrFolder.description || ''}
                                                onChange={(e) => handleEdit(e, 'updateFileOrFolder', 'description')}
                                                maxLength="255"
                                            ></textarea>
                                        </div>

                                        {/* Buttons */}
                                        <div className="flex justify-end mt-4 gap-x-2">
                                            <button
                                                type="submit"
                                                className="border border-1 border-primary bg-primary text-white px-6 py-2 rounded hover:bg-transparent hover:text-primary"
                                            >
                                                Update
                                            </button>
                                            <button
                                                type="button"
                                                className="mr-2 bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
                                                onClick={() => setEditWindow(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Place to add file folder edit window */}
                    {folderWindow && (
                        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-90 z-50">
                            <div className="bg-[#5090ad] border border-white rounded-lg shadow-lg w-[40vw] max-h-[95vh] p-6 overflow-y-auto">
                                <div className="relative">
                                    {/* Close Button */}
                                    <button className="absolute right-[-15px] top-[-15px]" onClick={() => setFolderWindow(false)}>
                                        <ImCross className="text-white bg-red-600 p-1 text-xl rounded-full hover:bg-red-700" />
                                    </button>

                                    {/* Form */}
                                    <form onSubmit={handleFolderSubmit}>
                                        <h1 className="text-[1.8em] pb-4 text-center font-semibold text-white brightness-200">
                                            Create New Folder
                                        </h1>
                                        <p className="text-center mx-auto pb-3 text-white w-[35vw] break-words">
                                            <span className="text-[1.2em] brightness-200">Creating in </span>
                                            <span className="brightness-150 text-gray-100">{releaseData?.path}</span>
                                        </p>

                                        {/* Folder Name Field */}
                                        <div className="mb-6 flex flex-row items-center justify-between">
                                            <label className="block text-lg text-white w-[19vw] brightness-200 ">Folder Name*</label>
                                            <input
                                                type="text" placeholder='Enter Directory Name'
                                                className="border p-3 w-[27vw] rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                onChange={(e) => handleEdit(e, 'newFolder', 'directoryName')}
                                                maxLength="100"
                                                required
                                            />
                                        </div>

                                        {/* CPU Architecture Select */}
                                        <div className="mb-6 flex flex-row items-center justify-between">
                                            <label className="block text-lg text-white w-[19vw] brightness-200 ">CPU Architecture*</label>
                                            <select
                                                className="border p-3 w-[27vw] rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                required
                                                defaultValue=""
                                                onChange={(e) => handleEdit(e, 'newFolder', 'cpuArchitecture')}
                                            >
                                                <option value="" disabled>Select Architecture</option>
                                                <option value="Common">Common</option>
                                                <option value="x64">x64</option>
                                                <option value="x86">x86</option>
                                            </select>
                                        </div>

                                        {/* IsLive Field */}
                                        <div className="mb-6 flex flex-row items-center justify-between">
                                            <label className="block text-lg text-white w-[19vw] brightness-200 ">Is Live*</label>
                                            <select
                                                required
                                                defaultValue=""
                                                className="border p-3 w-[27vw] rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                onChange={(e) => handleEdit(e, 'newFolder', 'isLive')}
                                            >
                                                <option value="" disabled >Make folder live</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>
                                        </div>

                                        {/* Description Field */}
                                        <div className="mb-6 flex flex-row items-center justify-between">
                                            <label className="block text-lg text-white w-[19vw] brightness-200 ">Description (Optional)</label>
                                            <textarea
                                                className="border p-3 w-[27vw] rounded focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                                                rows="4" placeholder='Directory description, if any...'
                                                onChange={(e) => handleEdit(e, 'newFolder', 'description')}
                                                maxLength="255"
                                            ></textarea>
                                        </div>

                                        {/* Buttons */}
                                        <div className="flex justify-end space-x-3">
                                            <button
                                                type="submit"
                                                className="border border-1 border-primary bg-primary text-white px-6 py-2 rounded hover:bg-transparent hover:text-primary"
                                            >
                                                Create
                                            </button>
                                            <button
                                                type="button"
                                                className="bg-gray-300 px-6 py-2 rounded hover:bg-gray-400 transition"
                                                onClick={() => setFolderWindow(false)}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )}

                    {showConfirmFirst === 'delete' && (
                        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50">
                            <div className="modal border-[1px] border-gray-400 min-w-[30vw] " >


                                <div className='text-center text-gray-600 flex-flex-row justify-center text-[3.8em]' style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <MdOutlineErrorOutline className='text-center' /></div>
                                <p className='py-[1vh] text-[1.5em] text-center font-semibold '>Are You sure?</p>
                                {/* <p className=' py-[1vh] text-red-600 flex flex-row gap-x-1 text-[1.1em] items-start'>
                                                <FaCircleInfo className='text-[1em]' /> Deleting this user will revoke your access to Matisoft's cloud platform.
                                            </p> */}

                                <p className='text-center'>You won't be able to revert this!</p>
                                <div className="modal-buttons gap-x-2 pt-[4vh] " style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                                    <button className='' onClick={() => handleDeleteConfirmFirst()}>Yes, Delete it!</button>
                                    <button onClick={() => setShowConfirmFirst(null)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )}
                    {showConfirmationModal === 'delete' && (
                        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50">
                            <div className="modal border-[1px] border-gray-400 " >
                                <div className='text-center text-gray-600 flex-flex-row justify-center text-[3.8em]' style={{ textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <MdOutlineErrorOutline className='text-center mb-[1vh]' /></div>
                                <p className='py-[2vh] text-[1.4em] font-semibold text-center'>Please confirm the details of <span className=' '>{selectedFile?.type}</span> to be deleted.</p>
                                {/* <p className=' py-[1vh] text-red-600 flex flex-row gap-x-1 text-[1.1em] items-start'>
                                                <FaCircleInfo className='text-[1em]' /> Deleting this user will revoke your access to Matisoft's cloud platform.
                                            </p> */}
                                <p style={{ wordBreak: 'break-all', overflowWrap: 'anywhere' }}>
                                    File Name: <span className='text-gray-800'>{selectedFile?.name}</span>
                                </p>
                                <p style={{ wordBreak: 'break-all', overflowWrap: 'anywhere' }}>
                                    File Path: <span className='text-gray-800'>{selectedFile?.path}</span>
                                </p>

                                <div className="modal-buttons gap-x-2 pt-[4vh]" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                                    <button className='' onClick={() => handleDelete()}>Confirm! Delete it!</button>
                                    <button onClick={() => setShowConfirmationModal(null)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* <hr /> */}

                    <div className={`absolute left-0 py-[1vh] border-t-[3px] ${releaseData?.isArchive ? 'border border-t-1 border-t-gray-500' : releaseData?.isLive ? 'border-t-1 border-t-[#40b3d6]' : ' border-t-1 border-yellow-500'} ${isPreviewVisible ? "right-[30vw]" : "right-0"} `}>
                        {/* Combine sub_directories and files into a single array */}
                        {(() => {
                            // Create a combined array with 'type' property
                            const combinedData = [
                                ...(releaseData?.sub_directories?.map((item) => ({ ...item, type: 'directory' })) || []),
                                ...(releaseData?.files?.map((item) => ({ ...item, type: 'file' })) || []),
                            ];

                            return combinedData.length > 0 ? (
                                <ul className={`grid justify-items-center items-start pt-[3vh] gap-y-[5vh] h-[79vh] overflow-x-hidden overflow-y-auto relative  ${isPreviewVisible ? 'grid-cols-4' : 'left-0 grid-cols-6 right-0'}`}>
                                    {combinedData.map((item, index) => (
                                        <li
                                            key={index}
                                            // className={`flex flex-col items-center justify-center w-full p-2 py-[4vh] 
                                            //     ${item?.isArchive ? "text-gray-400" : item?.isLive ? 'text-[#62cbe0]' : 'text-yellow-500'} 
                                            //     ${selectedFile?._id === item?._id ?
                                            //         releaseData?.isArchive ? 'border border-1 border-gray-400 bg-gray-200' :
                                            //             releaseData?.isLive ? ' border border-1  border-blue-400 bg-blue-200' : ' border border-1 border-yellow-400 bg-yellow-100'
                                            //         : ''}`}
                                            className={`flex flex-col items-center justify-center w-full p-2 py-[4vh] hover:bg-gray-100 cursor-pointer ${item?.isArchive === true ? "text-gray-400" : item?.isLive ? 'text-[#62cbe0]' : 'text-yellow-500'} ${selectedFile?._id === item?._id ? 'bg-gray-200' : ''}`}

                                            onClick={() => handleSingleClick(item)}
                                            onDoubleClick={() => handleFolderDoubleClick(item)}
                                            onContextMenu={(e) => handleFileRightClick(item, e)}
                                        >
                                            <div
                                                className='flex flex-col items-center'
                                                onDoubleClick={(e) => { e.stopPropagation(); handleFolderDoubleClick(item); }}
                                            >
                                                {item.type === 'directory' ? (
                                                    <FaFolder className='text-[4em]' />
                                                ) : (
                                                    <ImFileText2 className='text-[4em]' />
                                                )}
                                                <span className='ml-2 mt-2 text-gray-900 text-[0.8em] text-center  max-w-[11vw] break-words'>
                                                    {item?.name}
                                                </span>
                                            </div>
                                        </li>
                                    ))}
                                    {contextMenuVisible && (

                                        <ContextMenu
                                            position={contextMenuPosition}
                                            onClose={handleContextMenuClose}
                                            onSelect={handlePropertiesSelect}
                                        />
                                    )}
                                </ul>
                            ) : (
                                <p className='pl-1'>No data available.</p>
                            );
                        })()}

                        {/* Context Menu */}

                    </div>

                </div>

                {/* Preview Panel - Fixed on the Right */}
                {isPreviewVisible && (
                    <div className="fixed right-0 top-0 bg-white border border-gray-300 rounded-lg shadow-lg w-[30vw] h-full p-6 overflow-y-auto z-40">
                        <div className="relative">
                            <button className="absolute right-[-15px] top-[-15px]" onClick={() => setPreviewVisible(false)}>
                                <RxCross2 className="text-black text-[2em] p-1 rounded-full" />
                            </button>
                            <div className="flex flex-col gap-x-1 items-center justify-start shadow-md">
                                <div className='font-[800] max-w-[26vw] mx-auto text-center break-words'>
                                    <span className={` ${selectedFile?.isArchive ? 'text-gray-800' : selectedFile?.isLive ? 'text-[#00a3c7]' : "text-[#F59E0B]"}`}>
                                        {selectedFile?.name}
                                    </span>
                                </div>
                                <div>
                                    <span>{selectedFile ? selectedFile.type.charAt(0).toUpperCase() + selectedFile.type.slice(1) : ""}</span>
                                </div>

                            </div>
                            <div>
                                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                                    <TabList className="flex space-x-5 border-b pt-2 border-gray-300 mb-4">
                                        <Tab className={`p-2 cursor-pointer ${tabIndex === 0 ? 'border-b-2 font-[500] !border-[#4fd0ff]' : ''}`}>General</Tab>
                                        {/* <Tab className={`p-2 cursor-pointer ${tabIndex === 1 ? 'border-b-2 font-[500] !border-[#4fd0ff]' : ''}`}>Digital Signatures</Tab> */}
                                        <Tab className={`p-2 cursor-pointer ${tabIndex === 1 ? 'border-b-2 font-[500] !border-[#4fd0ff]' : ''}`}>Metadata</Tab>
                                    </TabList>

                                    <TabPanel className="pt-2 text-[0.9em] max-w-[30vw]">
                                        <div className="space-y-4">
                                            <div className="flex w-[28vw] overflow-x-hidden">
                                                <span className='w-[8vw]'>Name:</span>
                                                <span className='break-words max-w-[20vw]'>{selectedFile?.name || ''}</span>
                                            </div>
                                            <div className="flex w-[28vw] overflow-x-hidden">
                                                <span className='w-[8vw]'>Full Path:</span>
                                                <span className='break-words max-w-[20vw]'>{selectedFile?.path || ''}</span>
                                            </div>

                                            {selectedFile?.type === 'directory' && <>
                                                <div className="flex flex-wrap">
                                                    <span className='w-[8vw]'>Architecture:</span>
                                                    <span>{selectedFile?.architecture ?? ''}</span>
                                                </div>
                                                <div className="flex flex-wrap">
                                                    <span className='w-[8vw]'>Created On:</span>
                                                    <span>{selectedFile?.createdAt ? new Date(selectedFile.createdAt).toLocaleString() : ''}</span>
                                                </div>
                                                <div className="flex flex-wrap">
                                                    <span className='w-[8vw]'>Contains:</span>
                                                    <span>
                                                        {`${subDirectory?.file_count || 0} ${subDirectory?.file_count === 1 ? 'File' : 'Files'} & 
                                                         ${subDirectory?.sub_directory_count || 0} ${subDirectory?.sub_directory_count === 1 ? 'Directory' : 'Directories'}`}
                                                    </span>
                                                </div>
                                                <div className="flex w-[28vw] overflow-x-hidden">
                                                    <span className='w-[8vw]'>Description:</span>
                                                    <span className='flex-1 break-words max-w-[20vw]'>{selectedFile?.description ?? ''}</span>
                                                </div>
                                            </>}

                                            {selectedFile?.type === 'file' && <>

                                                <div className="flex flex-wrap">
                                                    <span className='w-[8vw]'>Size:</span>
                                                    <span>
                                                        {typeof selectedFile?.size === 'number'
                                                            ? `${(selectedFile.size / 1024).toFixed(2)} KB`
                                                            : ''}
                                                    </span>
                                                </div>
                                                <div className="flex flex-wrap">
                                                    <span className='w-[8vw]'>Version:</span>
                                                    <span>{selectedFile?.version ?? 'NA'}</span>
                                                </div>
                                                <div className="flex flex-wrap">
                                                    <span className='w-[8vw]'>Uploaded On:</span>
                                                    <span>{selectedFile?.createdAt ? new Date(selectedFile.createdAt).toLocaleString() : ''}</span>
                                                </div>
                                            </>}

                                        </div>
                                    </TabPanel>
                                    {/* <TabPanel className="pt-2 text-[0.9em] max-w-[30vw]">
                                        <div className="space-y-4">
                                            <div className="flex flex-wrap">
                                                <span className='w-[11vw]'>Status:</span>
                                            </div>
                                            <div className="flex flex-wrap">
                                                <span className='w-[11vw]'>Name of Signer:</span>
                                            </div>
                                            <div className="flex flex-wrap">
                                                <span className='w-[11vw]'>Digest Algorithm:</span>
                                            </div>
                                            <div className="flex flex-wrap">
                                                <span className='w-[11vw]'>Timestamp:</span>
                                            </div>
                                        </div>
                                    </TabPanel> */}
                                    <TabPanel className="pt-2 text-[0.9em] max-w-[30vw]">
                                        <div className="space-y-4">
                                            <div className="flex flex-wrap">
                                                <span className='w-[8vw]'>Status:</span>
                                                <span>{selectedFile?.isLive === true ? "Live" : selectedFile?.isLive === false ? "Non Live" : ''}</span>
                                            </div>
                                            <div className="flex flex-wrap">
                                                <span className='w-[8vw]'>Architecture:</span>
                                                <span>{selectedFile?.architecture || ''}</span>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Release;