import React from 'react'
import { styles } from '../styles'
import Slider from 'react-slick';
import '../css/style.css';
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { BiSolidRightArrow } from 'react-icons/bi';
const HeroHome1 = () => {

  const settings = {
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 8000,
    // lazyLoaded:true,

    fade: true,
    pauseOnHover: false,
    centerPadding: '0',
    easing: 'linear',
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },

    ],
  };
  return (
    <div className="custom-carousel-container">
      <Slider {...settings}>
        {/* DLP SLIDER */}

        {/* <div className='h-[90vh]   w-full mx-auto py-20 dlpHomeSlider ' id='irusp'>
          <div className={`container  inset-0 ${styles.paddingX} mx-auto py-[5vh] irfirst`}>
         
            <h1 className='text-white font-heroHeading py-3 z-10 irfirst font-bold md:max-w-[60%] lg:max-w-[54%] xl:max-w-[44%]  2xl:max-w-[59%] '>Data Leak Prevention</h1>
            <p className='font-subheading text-justify text-white md:max-w-[55%] lg:max-w-[52%] xl:max-w-[43%]  2xl:max-w-[57%] z-20 ir-firstSub py-[2vh] sm:py-[1vh]'>
            Matisoft DLP ensures 100% secure data transfer by preventing data leaks across emails, attachments, cloud uploads, websites, applications, and devices like pendrives, mobile phones, and Bluetooth, with features such as website blocking and a proprietary USB firewall.            </p>
            <div className='gap-4 flex flex-col sm:flex-row relative max-w-[50vw] min-w-[240px]' >
              <a href="/contact" className="btn btn-default flex flex-row  uppercase ">Book Your Demo </a>
              <a href="/dlp" className='btn btn-default flex flex-row   uppercase '>know more</a>
            </div>
        
          </div>
        </div> */}
        <div className='h-screen relative w-full mx-auto heroDlp'>
          <div className='overlay-layer'></div>
          <div className='text-layer absolute left-[10%] top-[10%] text-white w-[80%] margin-auto'>
            <h2 className='text-[1.3em] sm:text-[1.9em] 2xl:text-[2.3em] font-[600]'>Matisoft DLP prevents data leaks via:</h2>
            <ul className='text-[1.2em] sm:text-[1.5em] 2xl:text-[1.8em] pt-[2vh] flex flex-col gap-y-[1.4vh]'>
              <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                <span>Emails and Attachments</span>
              </li>
              <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                <span>Cloud uploads</span>
              </li>
              <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                <span>Website blocking</span>
              </li>
              <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                <span>Application blocking</span>
              </li>
              <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                <span>Pendrives, mobile phones, Bluetooth, and other devices.</span>
              </li>
              <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                <span>Proprietary USB firewall ensures 100% secure data transfer without data leaks or viruses.</span>
              </li>
              <li className='flex flex-col sm:flex-row items-start gap-x-[1vw] gap-y-[1vh] max-w-[50vw] min-w-[240px]'>
                <a href="/contact" className="btn btn-default flex flex-row   uppercase ">Contact an expert </a>
                <a href="/dlp" className='btn btn-default flex flex-row   uppercase '>know more</a>

              </li>
            </ul>

          </div>
        </div>

        {/* IR SLIDER */}

        <div className='h-[90vh]   w-full mx-auto py-20 irHomeSlider ' id='irusp'>
          <div className={`container  inset-0 ${styles.paddingX} mx-auto py-[5vh] irfirst`}>
            {/* <p className={`${styles.sectionSubText} text-gray-400 `}>Matisoft Services</p> */}
            <h1 className='text-white  font-heroHeading font-bold md:max-w-[77%] lg:max-w-[72%] xl:max-w-[60%] 2xl:max-w-[77%] py-3 z-10 irfirst md:text-justify'>Incident Response Services</h1>
            <p className='font-subheading text-justify text-white  md:max-w-[74%] lg:max-w-[69%]  xl:max-w-[57%] 2xl:max-w-[75%]  z-20 ir-firstSub py-[1vh]'>
              Our highly skilled and experienced team helps you recover from advanced cyber attacks like <span className='text-secondary text-lg 2xl:text-[28px]' > ShadowPad, PlugX, Stuxnet, WMI-Ghost, DJVU Ransomware, </span>etc.
            </p>

            <div className='gap-4 flex flex-col sm:flex-row relative z-20 max-w-[50vw] min-w-[240px]' >
              <a href="/contact" className="btn btn-default flex flex-row   uppercase ">Contact an expert </a>
              <a href="/ir-services" className='btn btn-default flex flex-row   uppercase '>know more</a>
            </div>
            {/* UspIr */}
          </div>
        </div>

        {/* EDR SLIDER */}

        <div className='h-[90vh]   w-full mx-auto py-20 edrHomeSlider' id='irusp'>
          <div className={`container  inset-0 ${styles.paddingX} mx-auto py-[5vh] irfirst`}>
            {/* <p className={`${styles.sectionSubText} text-gray-400 `}>Matisoft Services</p> */}
            <h1 className='text-white font-heroHeading py-3 z-10 font-bold irfirst md:max-w-[70%] lg:max-w-[75%] xl:max-w-[66%]  2xl:max-w-[58%] md:text-justify'>Endpoint Detection & Response (EDR) </h1>
            <p className='font-subheading text-justify text-white  z-20 ir-firstSub md:max-w-[64%] lg:max-w-[74%] xl:max-w-[65%]  2xl:max-w-[57%] py-[1vh]'>
              It is one of the most advanced AI based ATP (Advanced Threat Protection) antivirus developed from the combined experience of ethical hackers, virus researchers and AI experts.
            </p>
            <div className='gap-4 flex flex-col sm:flex-row relative max-w-[50vw] min-w-[240px]' >
              <a href="/contact" className="btn btn-default flex flex-row  uppercase ">Contact an expert </a>
              <a href="/ams" className='btn btn-default flex flex-row   uppercase '>know more</a>
            </div>
            {/* UspIr */}
          </div>
        </div>

      </Slider></div>
  )
}
const CustomPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      {/* <FaArrowAltCircleLeft /> */}
      <IoIosArrowDropleftCircle />
    </div>

  );
};

// Custom arrow component for next button
const CustomNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next color-pink-600" onClick={onClick}>
      {/* <FaArrowAltCircleRight /> */}
      {/* <MdDoubleArrow /> */}
      <IoIosArrowDroprightCircle />

    </div>
  );
};

export default HeroHome1;