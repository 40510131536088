import axios from 'axios'; // Import Axios
import { toast } from 'react-toastify';

import {
    REQUEST_FAILED_LICENCE_LINKED_DEVICES, RECEIVE_LICENCE_LINKED_DEVICES, REQUEST_LICENCE_LINKED_DEVICES, SET_PAGE_NUMBER_LICENCE_LINKED_DEVICES, SET_PAGE_SIZE_LICENCE_LINKED_DEVICES, SET_FILTER_LICENCE_LINKED_DEVICES, SET_SORT_OPTIONS_LICENCE_LINKED_DEVICES
} from './licence.action_type';
import { setPermissions } from '../companyid/companyid.action';

export const fetchLinkedDevices = (userId, orgId, productCode, licenseId, pageSize, page, sortBy, sortOrder, filtersvalue) => {
    return async (dispatch) => {
        // console.log(filtersvalue, "filtersvaluekkkkkkkkk");

        dispatch({ type: REQUEST_LICENCE_LINKED_DEVICES });
        // console.log(filtersvalue, "filtersvaluekkkkkkkkk");

        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/api/license/device`, {
                ...filtersvalue,
                pageSize
                , page
                , sortBy
                , sortOrder,
                "orgId": orgId,
                "userId":userId,
                "product_code": productCode,
                // licenseId: licenseId
            });
            dispatch({ type: RECEIVE_LICENCE_LINKED_DEVICES, payload: response.data });
            if (response?.data?.permissions) {
                dispatch(setPermissions(response.data.permissions));
              }
            // console.log('Data from backend:', response.data);
            // toast.success("Licence List retrieved successfully");
        } catch (error) {
            // console.error('Error fetching data:', error);
            dispatch({ type: REQUEST_FAILED_LICENCE_LINKED_DEVICES, payload: error });
            toast.error(error?.response?.data || error?.message);
        }
    };
};

export const setSortOptions = (sortBy, sortOrder) => ({
    type: SET_SORT_OPTIONS_LICENCE_LINKED_DEVICES,
    payload: { sortBy, sortOrder }
});

export const setPageSize = (pageSize) => ({
    type: SET_PAGE_SIZE_LICENCE_LINKED_DEVICES,
    payload: pageSize,
});

export const setPageNumber = (pageNumber) => ({
    type: SET_PAGE_NUMBER_LICENCE_LINKED_DEVICES,
    payload: pageNumber,
});

export const setFilters = (filters) => ({
    type: SET_FILTER_LICENCE_LINKED_DEVICES,
    payload: filters,
});
