
import {
    REQUEST_DASHBOARD_ALERTS_DATA,
    REQUEST_FAILED_DASHBOARD_ALERTS_DATA,
    RECEIVE_DASHBOARD_ALERTS_DATA,
   
  } from './dashboard.action_type';
  
  const initialState = {
    sortBy: 'createdAt', // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    loadingDashboardAlert: false,
    totalRecordsAlerts: 0,
    errorDashboardAlert: null,
    whitelistCount:0,
    quarantineCount:0,
    deletedCount:0,
    customSolutionCount:0,
    toBeAnalysedCount:0,
    filters: {},
    pageSize: 100,
    pageNumber: 1,
    lastSortBy: null,
    lastSortOrder: null,
  };
  
  const reducer = (state = initialState, action) => {
    
    switch (action.type) {
      case REQUEST_DASHBOARD_ALERTS_DATA:
        return {
          ...state,
          loadingDashboardAlert: true,
        };
      case RECEIVE_DASHBOARD_ALERTS_DATA:
        return {
          ...state,
          loadingDashboardAlert: false,
        //  infectedData: action.payload.infected_computer,
          // linkedDevices:action.payload.linkedDevices,
          // unlinkedDevices:action.payload.unlinkedDevices,
          whitelistCount:action.payload.whilteListAlertCount,
          quarantineCount:action.payload.quarantineAlertCount,
          deletedCount:action.payload.deletedAlert,
          toBeAnalysedCount:action.payload.toBeAnalystAlert,
          customSolutionCount:action.payload.customActionAlert,
          totalRecordsAlerts: action.payload.totalRecords,
          errorDashboardAlert: null,
        };
      case REQUEST_FAILED_DASHBOARD_ALERTS_DATA:
        return {
          ...state,
          loadingDashboardAlert: false,
          errorDashboardAlert: action.payload,
        };
    
      default:
        return state;
    }
  };
  
  export { reducer };
  