
import { REQUEST_ACTION_COMPUTER, SET_ACTION_COMPUTER, ERROR_ACTION_COMPUTER, SET_PAGE_SIZE, SET_PAGE_NUMBER, SET_FILTERS, SET_SORT_OPTIONS } from "./actionComputer.action_type"
const initialState = {
    loading: false,
    computerData: [],
    sortBy: 'createdAt', // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    // data: [],
    loading: false,

    totalRecords: 0,
    error: null,
    filters: {},
    pageSize: 100,
    pageNumber: 1,
    error: false,
  
}
const reducer = (state = initialState, action) => {
    // console.log(state.computerData, "computerData");
    // console.log(state.totalRecords,"records");
    switch (action.type) {
        case REQUEST_ACTION_COMPUTER:
            return {
                ...state,
                loading: true,
            }
        case SET_ACTION_COMPUTER:
            return {
                ...state,
                loading: false,
                computerData: action.payload.devices,
                totalRecords:action.payload.totalRecords,
            }
        case ERROR_ACTION_COMPUTER:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case SET_SORT_OPTIONS:
            return {
                ...state,
                lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
                lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
                sortBy: action.payload.sortBy,
                sortOrder: action.payload.sortOrder,
            };
        case SET_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.payload,
            };
        case SET_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_FILTERS: {
            return {
                ...state,
                filters: action.payload,
            }
        }
        default: return state;
    }
}
export { reducer };