
import {
    REQUEST_USER_LICENCE_LIST,
    REQUEST_FAILED_USER_LICENCE_LIST,
    RECEIVE_USER_LICENCE_LIST,
    SET_SORT_OPTIONS_USER_LICENCE_LIST,
    SET_PAGE_SIZE_USER_USER_LICENCE_LIST,
    SET_PAGE_NUMBER_USER_LICENCE_LIST,
    SET_FILTER_USER_LICENCE_LIST,
  } from './userLicence.action_type';
  const initialState = {
    loading: false,
    computerData: [],
    sortBy: 'createdAt', // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    // data: [],
    loading: false,
    userLicenceList: [],
    totalRecords: 0,
    error: null,
    filters: {},
    pageSize: 100,
    pageNumber: 1,
    error: false,

}
const reducer = (state = initialState, action) => {
//    console.log(state,"user License");
   
    switch (action.type) {
        case REQUEST_USER_LICENCE_LIST:
            return {
                ...state,
                loading: true,
            }
        case RECEIVE_USER_LICENCE_LIST:
            return {
                ...state,
                loading: false,
                userLicenceList: action.payload.data,
                // totalRecords:action.payload.totalRecords,
            }
        case REQUEST_FAILED_USER_LICENCE_LIST:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case SET_SORT_OPTIONS_USER_LICENCE_LIST:
            return {
                ...state,
                lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
                lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
                sortBy: action.payload.sortBy,
                sortOrder: action.payload.sortOrder,
            };
        case SET_PAGE_SIZE_USER_USER_LICENCE_LIST:
            return {
                ...state,
                pageSize: action.payload,
            };
        case SET_PAGE_NUMBER_USER_LICENCE_LIST:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_FILTER_USER_LICENCE_LIST: {
            return {
                ...state,
                filters: action.payload,
            }
        }
        default: return state;
    }
}
export { reducer };