import React from 'react'
import { styles } from '../styles';
import { BiSolidRightArrow } from "react-icons/bi";

const DlpHero1 = () => {
    return (
        <div className='h-screen relative w-full mx-auto heroDlp'>
            <div className='overlay-layer'></div>
            <div className='text-layer absolute left-[10%] top-[10%] text-white w-[80%] margin-auto'>
                <h2 className='text-[1.6em] sm:text-[1.9em] 2xl:text-[2.3em] font-[600]'>Matisoft DLP prevents data leaks via:</h2>
                <ul className='text-[1.3em] sm:text-[1.5em] 2xl:text-[1.8em] pt-[2vh] flex flex-col gap-y-[1.4vh]'>
                    <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                        <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                        <span>Emails and Attachments</span>
                    </li>
                    <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                        <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                        <span>Cloud uploads</span>
                    </li>
                    <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                        <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                        <span>Website blocking</span>
                    </li>
                    <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                        <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                        <span>Application blocking</span>
                    </li>
                    <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                        <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                        <span>Pendrives, mobile phones, Bluetooth, and other devices.</span>
                    </li>
                    <li className='grid grid-cols-[auto,1fr] items-start gap-x-[0.6vw]'>
                        <BiSolidRightArrow className='text-secondary mt-[0.2em]' />
                        <span>Proprietary USB firewall ensures 100% secure data transfer without data leaks or viruses.</span>
                    </li>
                    <li>  <a href="/contact" className=" btn btn-default text-white font-[500] uppercase">Book your demo</a>
</li>
                </ul>

            </div>
        </div>
    )
}

export default DlpHero1