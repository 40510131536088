// Loader.js

import React from 'react';
// Import the CSS file for Loader component

const Loader = () => {
  return (
    <div id="loader-outer">
      <div id="loader"></div>
    </div>
    // <div className='text-center'>.....Loading</div>
  );
}

export default Loader;
