
import {
  RECEIVE_WORKQUEUE_DATA,
  REQUEST_FAILED_WORKQUEUE_DATA,

  SET_FILTER_WORKQUEUE,
  SET_PAGE_NUMBER_WORKQUEUE,
  SET_SORT_OPTIONS_WORKQUEUE,
  SET_PAGE_SIZE_WORKQUEUE,
  REQUEST_WORKQUEUE_DATA

} from './workQueue.action_type';

const initialState = {
  sortBy: 'createdAt', // Default value for sortBy
  sortOrder: -1, // Default value for sortOrder
  // data: [],
  loading: false,
  workQueueData: [],
  totalRecords: 0,
  error: null,
  filters: {},
  pageSize: 100,
  pageNumber: 1,
  lastSortBy: null,
  lastSortOrder: null,
};

const reducer = (state = initialState, action) => {
//   console.log(state.filters, "datainalertinstancesssss");
  switch (action.type) {
    case REQUEST_WORKQUEUE_DATA:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_WORKQUEUE_DATA:
      return {
        ...state,
        loading: false,
        workQueueData: action.payload.work_queue,
        totalRecords: action.payload.totalRecords,
        error: null,
      };
    case REQUEST_FAILED_WORKQUEUE_DATA:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_SORT_OPTIONS_WORKQUEUE:
      return {
        ...state,
        lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
        lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    case SET_PAGE_SIZE_WORKQUEUE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SET_PAGE_NUMBER_WORKQUEUE:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case SET_FILTER_WORKQUEUE:
      return {
        ...state,
        filters: action.payload,
      }
    default:
      return state;
  }
};

export { reducer };
