import React from 'react'
import { styles } from '../styles'
import Slider from "react-slick";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
const HistoryIr = () => {
  const historyVirus=[
    {name:"ShadowPad",origin:"Discovered in 2017, originated from multiple groups like APT41, Barium, Winnti, etc.",target:"Target critical infrastructure such as energy, power, healthcare, telecommunications, etc.",influence:"Ability to infiltrate diverse industries through supply chain attacks, enabling remote access for espionage, data theft etc."},
    {name:"PlugX",origin:" Origin by Mustang Panda (APT29) group in 2008 ",target:"Target government, defense, tech, and aerospace entities.",influence:"Impact lies in the theft of sensitive information, intellectual property, and strategic data."},
    {name:"Stuxnet",origin:"Speculated place of origin U.S. & Israel.",target:"200,000 computers and 1,000 machines were infected.",influence:"$50-60 billion losses."},
    {name:"WMI Ghost",origin:"Discovered in 2010 & still present in the wild",target:"Estimated losses in billions $",influence:"Estimated Infections 500,000 systems"},
    {name:"DJVU Ransomware",origin:"Also known as STOP ransomware,emerged around 2018",target:"Spreads through malicious emails, software cracks, fake updates, and compromised installers.",influence:"Caused financial losses, disrupts operations, encrypts data, and harms the affected parties' reputations."},

  ]
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows:true,
    autoplay: true,
    autoplaySpeed: 5000, 
    // fade: true,
    fade:false,
    centerMode: true,
    centerPadding: '0',
    easing: 'linear',
   
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 970,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className=" h-screen  mx-0 px-0 bg-zinc-600 historyIr" id="historyIR">
      <div className={`container ${styles.paddingX} mx-auto py-[10vh] `}>
        <div className=" text-center mx-auto pb-[4vh]">
           
                <h2 className='font-heroHeading text-center mx-auto text-cyan-200'>HISTORY’S MOST POWERFUL ATTACKS  WE HAVE CAUGHT</h2>
        
      </div>
      <div>
      <Slider {...settings} className="ml-0 ">
        {historyVirus.map((item,index)=>(
      <div className=''>

          <div key={index} className="historyBoxIR px-1 mx-auto " >

          <h3 className='font-aboveMed text-cyan-300 relative z-30 text-center h-[10vh]'>{item.name}</h3>
            {/* <h4 className='font-low text-white'>{item.origin}</h4> */}

        <ul className='historyIr-points '>
          <li className='  text-gray-100 text-sm xl:text-sm 2xl:text-lg'>{item.origin}</li>
          <li className=' text-gray-200 text-sm xl:text-sm 2xl:text-lg'>{item.target}</li>
          <li className=' text-gray-200  text-sm xl:text-sm 2xl:text-lg' >{item.influence}</li>
        </ul>
        </div>
            
          </div>
        ))}
        </Slider>
      </div>
    
    </div>
</div>
  );
}

export default HistoryIr
