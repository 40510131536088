import { verifyUserList } from "./verifyUser.action";
import { RECEIVE_VERIFY_USER, REQUEST_FAILED_VERIFY_USER, REQUEST_VERIFY_USER, SET_FILTER_VERIFY_USER, SET_PAGE_NUMBER_VERIFY_USER, SET_PAGE_SIZE_VERIFY_USER, SET_SORT_OPTIONS_VERIFY_USER } from "./verifyUser.action_type"

const initialState = {
    loading: false,

    sortBy: 'created_at', // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    loading: false,
    verifyUserList: [],
    totalRecords: 0,
    error: null,
    filters: {},
    pageSize: 100,
    pageNumber: 1,
    error: false,

}
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case REQUEST_VERIFY_USER:
            return {
                ...state,
                loading: true,
            }
        case RECEIVE_VERIFY_USER:
            return {
                ...state,
                loading: false,
               verifyUserList: action.payload.data,
                totalRecords:action.payload.totalRecords,
            }
        case REQUEST_FAILED_VERIFY_USER:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case SET_SORT_OPTIONS_VERIFY_USER:
            return {
                ...state,
                lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
                lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
                sortBy: action.payload.sortBy,
                sortOrder: action.payload.sortOrder,
            };
        case SET_PAGE_SIZE_VERIFY_USER:
            return {
                ...state,
                pageSize: action.payload,
            };
        case SET_PAGE_NUMBER_VERIFY_USER:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_FILTER_VERIFY_USER:
            return {
                ...state,
                filters: action.payload,
            }

        default: return state;
    }
}
export { reducer };