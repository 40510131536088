import { RECEIVE_USER_ROLE, REQUEST_FAILED_USER_ROLE, REQUEST_USER_ROLE, SET_FILTER_USER_ROLE, SET_PAGE_NUMBER_USER_ROLE, SET_PAGE_SIZE_USER_ROLE, SET_SORT_OPTIONS_USER_ROLE } from "./userRoles.action_type"

const intialState = {
    loading: false,
    userRolesData: [],
    error: null,
    sortBy: 'createdAt', // Default value for sortBy
    sortOrder: -1, // Default value for sortOrder
    totalRecords: 0,
    error: null,
    filters: {},
    pageSize: 100,
    pageNumber: 1,
}

const reducer = (state = intialState, action) => {
    // console.log(state,"user role state ");

    switch (action.type) {
        case REQUEST_USER_ROLE:
            return {
                ...state,
                loading: true,

            }
        case RECEIVE_USER_ROLE:
            return {
                ...state,
                loading: false,
                userRolesData: action.payload.data,
                totalRecords: action.payload.totalRecords,
                error: null
            }
        case REQUEST_FAILED_USER_ROLE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case SET_SORT_OPTIONS_USER_ROLE:
            return {
                ...state,
                lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
                lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
                sortBy: action.payload.sortBy,
                sortOrder: action.payload.sortOrder,
            };
        case SET_PAGE_SIZE_USER_ROLE:
            return {
                ...state,
                pageSize: action.payload,
            };
        case SET_PAGE_NUMBER_USER_ROLE:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case SET_FILTER_USER_ROLE: {
            return {
                ...state,
                filters: action.payload,
            }
        }
        default: return state
    }


}
export { reducer };