import { REQUEST_AUTHENTICATE, ERROR_AUTHENTICATE, SET_AUTHENTICATE } from './auth.action_type';

const initialState = {
  loading: false,
  authenticated: false,
  error: null,
  credentials:{}
};

const reducer = (state = initialState, action) => {
  // console.log(state.crdentials,"state auth crdentials");
  switch (action.type) {
    case REQUEST_AUTHENTICATE:
      return {
        ...state,
        loading: true,
      };
    case SET_AUTHENTICATE:
      localStorage.setItem('userCredentials', JSON.stringify(action.payload.data));

      return {
        ...state,
        loading: false,
        credentials:action.payload.data,
        authenticated: true,
        error: null,
      };
    case ERROR_AUTHENTICATE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export {reducer};
