export const REQUEST_FAILED_LICENCE_HISTORY = 'REQUEST_FAILED_LICENCE_HISTORY';
export const REQUEST_LICENCE_HISTORY = 'REQUEST_LICENCE_HISTORY';
export const RECEIVE_LICENCE_HISTORY = 'RECEIVE_LICENCE_HISTORY';
export const SET_SORT_OPTIONS_LICENCE_HISTORY= 'SET_SORT_OPTIONS_LICENCE_HISTORY';
export const SET_PAGE_SIZE_LICENCE_HISTORY = 'SET_PAGE_SIZE_LICENCE_HISTORY';
export const SET_PAGE_NUMBER_LICENCE_HISTORY = 'SET_PAGE_NUMBER_LICENCE_HISTORY';
export const SET_FILTER_LICENCE_HISTORY = 'SET_FILTER_LICENCE_HISTORY';
export const REQUEST_FAILED_LICENCE_LIST = 'REQUEST_FAILED_LICENCE_LIST';
export const REQUEST_LICENCE_LIST = 'REQUEST_LICENCE_LIST';
export const RECEIVE_LICENCE_LIST = 'RECEIVE_LICENCE_LIST';
export const SET_SORT_OPTIONS_LICENCE_LIST= 'SET_SORT_OPTIONS_LICENCE_LIST';
export const SET_PAGE_SIZE_LICENCE_LIST = 'SET_PAGE_SIZE_LICENCE_LIST';
export const SET_PAGE_NUMBER_LICENCE_LIST = 'SET_PAGE_NUMBER_LICENCE_LIST';
export const SET_FILTER_LICENCE_LIST = 'SET_FILTER_LICENCE_LIST';

export const REQUEST_FAILED_LICENCE_HISTORY_USER = 'REQUEST_FAILED_LICENCE_HISTORY_USER';
export const REQUEST_LICENCE_HISTORY_USER = 'REQUEST_LICENCE_HISTORY_USER';
export const RECEIVE_LICENCE_HISTORY_USER = 'RECEIVE_LICENCE_HISTORY_USER';
export const SET_SORT_OPTIONS_LICENCE_HISTORY_USER= 'SET_SORT_OPTIONS_LICENCE_HISTORY_USER';
export const SET_PAGE_SIZE_LICENCE_HISTORY_USER = 'SET_PAGE_SIZE_LICENCE_HISTORY_USER';
export const SET_PAGE_NUMBER_LICENCE_HISTORY_USER = 'SET_PAGE_NUMBER_LICENCE_HISTORY_USER';
export const SET_FILTER_LICENCE_HISTORY_USER = 'SET_FILTER_LICENCE_HISTORY_USER';

export const REQUEST_FAILED_LICENCE_LINKED_DEVICES = 'REQUEST_FAILED_LICENCE_LINKED_DEVICES';
export const REQUEST_LICENCE_LINKED_DEVICES = 'REQUEST_LICENCE_LINKED_DEVICES';
export const RECEIVE_LICENCE_LINKED_DEVICES = 'RECEIVE_LICENCE_LINKED_DEVICES';
export const SET_SORT_OPTIONS_LICENCE_LINKED_DEVICES= 'SET_SORT_OPTIONS_LICENCE_LINKED_DEVICES';
export const SET_PAGE_SIZE_LICENCE_LINKED_DEVICES = 'SET_PAGE_SIZE_LICENCE_LINKED_DEVICES';
export const SET_PAGE_NUMBER_LICENCE_LINKED_DEVICES = 'SET_PAGE_NUMBER_LICENCE_LINKED_DEVICES';
export const SET_FILTER_LICENCE_LINKED_DEVICES = 'SET_FILTER_LICENCE_LINKED_DEVICES';