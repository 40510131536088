import React, { useEffect, useState } from 'react';
import Header from '../../Header1';
import { passwordStrength } from 'check-password-strength'
import { FaCheck } from 'react-icons/fa6';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify'; // Assuming toast is being used for notifications
// import startsWith from 'lodash.startswith';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const CompleteSignUp = () => {
    const navigate = useNavigate();
    const [signUpData, setSignUpData] = useState({
        companyName: '',
        industry: '',
        title: '',
        currentAntivirus: '',
        deviceCount: '',
        groupName: '',
        country: '',
        state: '',
        password: '',
        // department: '',
        token: '',
        description: '',
        _id: ''
    });
    const [confirmPassword, setConfirmPassword] = useState('');
    const [preFillData, setPreFillData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        companyName: '',
        country: '',
    })
    const [password, setPassword] = useState('');
    const [score, setScore] = useState({
        length: 0,
        value: '',
        contains: []
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'confirmPassword') {
            setConfirmPassword(event.target.value);
            return;
        }
        setSignUpData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'password') {
            const passwordStrengthResult = passwordStrength(value);
            console.log(passwordStrengthResult);
            setScore((prevScore) => ({
                ...prevScore,
                value: passwordStrengthResult.value,
                length: passwordStrengthResult.length,
                contains: passwordStrengthResult.contains
                // corrected 'lentgh' to 'length'
            }));
        }

    };
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    useEffect(() => {
        const uuid = window.location.pathname.split('/').pop(); // Extract the last part of the URL
        axios.get(`${process.env.REACT_APP_HOST_NAME}/api/webuser/fetchUser/${uuid}`)
            .then((response) => {
                // Handle the response here
                setPreFillData({
                    firstName: response.data.first_name,
                    lastName: response.data.last_name,
                    email: response.data.email,
                    phone: response.data.phone,
                    companyName: response.data.company_name,
                    country: response.data.country,
                })
                setSignUpData((prevData) => ({
                    ...prevData,
                    country: response.data.country,
                    groupName: response.data.company_name,
                    companyName: response.data.company_name,
                    token: response.data.token,
                    _id: response.data._id
                }))
                console.log(response?.data);
            })
            .catch((error) => {
                // Handle any errors here
                toast.error(error?.response?.data || error?.message)
                console.error(error);
            });
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        // console.log(signUpData, "sign Up Data");

        // Validate required fields
        if (signUpData.deviceCount && signUpData.country && signUpData.state && signUpData.password && confirmPassword === signUpData.password) {
            // Check password strength
            if (score.value === 'Weak' || score.value === 'Too weak') {
                toast.error('Password strength is weak. Please choose a stronger password.');
                setFormSubmitted(false);
                return;
            }

            // All required fields are filled, passwords match, and password strength is acceptable
            axios.post(`${process.env.REACT_APP_HOST_NAME}/api/webuser/complete-signup`, { ...signUpData })
                .then((response) => {
                    // console.log(response, "formSubmission");
                    toast.success("Submitted successfully");

                    // Reset form fields
                    setSignUpData({
                        companyName: '',
                        industry: '',
                        title: '',
                        currentAntivirus: '',
                        deviceCount: '',
                        country: '',
                        state: '',
                        groupName: '',
                        token: '',
                        password: '',
                        description: '',
                        _id: ''
                    });

                    // Reset confirm password
                    setConfirmPassword('');

                    setFormSubmitted(false);
                    navigate('/login');
                })
                .catch((err) => {
                    console.error(err);
                    toast.error(err?.message || "Submission failed. Please try again.");
                });

            console.log('Form submitted', signUpData);
        } else {
            // Handle validation failure
            if (confirmPassword !== signUpData.password) {
                toast.error('Passwords do not match');
            } else {
                toast.error('Please fill all required fields');
            }
        }
    };


    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');

    const selectCountry = (val) => {
        setSignUpData((preData) => ({
            ...preData,
            country: val
        }));
        console.log(val, "country");
    };

    const selectRegion = (val) => {
        setSignUpData((preData) => ({
            ...preData,
            state: val
        }));
    };
    const handlePhoneChange = (val, countryData, e, formattedValue) => {
        setSignUpData((preData) => ({
            ...preData,
            phone: formattedValue,  // Save the formatted value with country code
        }));
    };

    const isValidPhoneNumber = (inputNumber, country, countries) => {
        return countries.some((country) => {
            return inputNumber.startsWith(country.dialCode) || country.dialCode.startsWith(inputNumber);
        });
    };
    return (
        <>
            <Helmet>
                <title>Matisoft | Matisoft Account Creation</title>
            </Helmet>
            <Header />
            <div className='bg-gray-200 h-full w-full completeSignUp'>
                <div className=' w-[90vw] mx-auto bg-blue-100 p-6 rounded-lg shadow-lg'>
                    <div className='text-center py-4'>
                        <h1 className='text-[1.8em] py-2'>MATISOFT ACCOUNT CREATION</h1>
                        <p className='text-[0.8em] py-2'>Fill in your information and sign up with us to get access to more information and updates.</p>
                    </div>
                    <form onSubmit={handleSubmit} >
                        <div className=' gap-4 pt-4 text-[0.8em] font-[400] text-gray-900'>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-[1vw] gap-y-[2vh]'>

                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>First Name</label>
                                    <input type='text' placeholder='First Name'
                                        name='firstName' value={preFillData.firstName}
                                        className='border p-2 rounded' maxLength='50' disabled />
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Last Name</label>
                                    <input type='text' name='lastName' maxLength='50'
                                        disabled value={preFillData.lastName} className='border p-2 rounded' />
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Email Id</label>
                                    <input type='email' name='email' maxLength='30' value={preFillData.email} disabled
                                        className='border p-2 rounded' />
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Phone Number</label>
                                    <input type='tel' name='phone' value={preFillData.phone} className='border p-2 rounded' disabled />
                                    {/* <PhoneInput
                                        country={'us'}
                                        value={preFillData.phone} disabled
                                        // onChange={handlePhoneChange}
                                        inputProps={{
                                            name: 'phone',
                                            required: false,
                                            autoFocus: false,
                                            placeholder: 'Enter phone number',
                                        }}
                                        containerStyle={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            padding: '0px',
                                            
                                        }}
                                        inputStyle={{
                                            width: '100%',
                                            backgroundColor:'#cfe0e2 '
                                        }}
                                        buttonStyle={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor:'#cfe0e2 '
                                        }}
                                        dropdownStyle={{
                                            padding: '8px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'flex-start',
                                        }}
                                        isValid={isValidPhoneNumber}
                                    /> */}
                                    {/* Display the formatted phone number for debugging purposes */}
                                    {/* <div>Formatted Phone Number: {signUpData.phone}</div> */}
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Organisation Name</label>
                                    <input type='text' name='companyName'
                                        value={preFillData.companyName} onChange={handleChange}
                                        maxLength='50' disabled
                                        className='border p-2 rounded' />
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Industry Name*</label>
                                    <select name='industry' value={signUpData.industry} onChange={handleChange} required className='border p-2 rounded bg-white'>
                                        <option value="">Select...</option>
                                        <option>Accounting</option>
                                        <option>Administration & Office Support</option>
                                        <option>Advertising, Arts & Media</option>
                                        <option>Banking & Financial Services</option>
                                        <option>Call Centre & Customer Service</option>
                                        <option>Channel Partner</option>
                                        <option>Community Services & Development</option>
                                        <option>Construction</option>
                                        <option>Consulting & Strategy</option>
                                        <option>Defence</option>
                                        <option>Design & Architecture</option>
                                        <option>Education & Training</option>
                                        <option>Engineering</option>
                                        <option>Farming, Animals & Conservation</option>
                                        <option>Government</option>
                                        <option>Healthcare & Medical</option>
                                        <option>Hospitality & Tourism</option>
                                        <option>Human Resources & Recruitment</option>
                                        <option>Information & Communication Technology</option>
                                        <option>Insurance & Superannuation</option>
                                        <option>Legal</option>
                                        <option>Manufacturing, Transport & Logistics</option>
                                        <option>Marketing & Communications</option>
                                        <option>Mining, Resources & Energy</option>
                                        <option>Other</option>
                                        <option>Real Estate & Property</option>
                                        <option>Retail & Consumer Products</option>
                                        <option>Sales</option>
                                        <option>Science & Technology</option>
                                        <option>Sport & Recreation</option>
                                        <option>Trades & Services</option>
                                    </select>
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Who is your current antivirus provider ?*</label>
                                    <select name='currentAntivirus' value={signUpData.currentAntivirus} onChange={handleChange} required className='border p-2 rounded bg-white'>
                                        <option value="">Select...</option>
                                        <option>AVG</option>
                                        <option>Avast</option>
                                        <option>Bitdefender</option>
                                        <option>CrowdStrike</option>
                                        <option>Cylance</option>
                                        <option>Cybereason</option>
                                        <option>Carbon Black</option>
                                        <option>DeepInstinct</option>
                                        <option>Eset</option>
                                        <option>F-Secure</option>
                                        <option>FireEye</option>
                                        <option>Kaspersky</option>
                                        <option>Malwarebytes</option>
                                        <option>McAfee</option>
                                        <option>Microsoft</option>
                                        <option>Quick Heal</option>
                                        <option>Seqrite</option>
                                        <option>Sophos</option>
                                        <option>Symantec</option>
                                        <option>Trend Micro</option>
                                        <option>Webroot</option>
                                        <option>Others</option>
                                    </select>
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500] '>Job Role*</label>
                                    <select name='title' value={signUpData.title} onChange={handleChange} required className='border p-2 rounded bg-white'>
                                        <option value="">Select...</option>
                                        <option>CEO</option>
                                        <option>CISO/CSO</option>
                                        <option>CTO</option>
                                        <option>CIO</option>
                                        <option>COO</option>
                                        <option>CMO</option>
                                        <option>IT Professional</option>
                                        <option>Security Professional</option>
                                        <option>Director</option>
                                        <option>Reseller</option>
                                        <option>VP</option>
                                        <option>Sales/Marketing</option>
                                        <option>Business Development</option>
                                        <option>Student/Intern</option>
                                        <option>Others</option>
                                    </select>
                                </div>
                                <div className='flex flex-col'>
                                    <label className='font-[500] mb-2'>In which department would you like to add computers?</label>
                                    <input type='text' name='groupName' value={signUpData.groupName} onChange={handleChange} className='border p-2 rounded bg-white' />
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500] '>Number of Devices*</label>
                                    <input type='number' name='deviceCount'
                                        value={signUpData.deviceCount} onChange={handleChange}
                                        required min='1' className='border p-2 rounded bg-white' maxLength='10000000000' style={{ backgroundColor: 'white' }} />
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>Country</label>
                                    <CountryDropdown
                                        classes='border p-2 rounded text-black'
                                        value={preFillData.country} disabled
                                    // onChange={selectCountry} 
                                    />{formSubmitted && !signUpData.country && (
                                        <span className="text-red-600 pl-[1vw]">This field is required</span>
                                    )}
                                </div>
                                <div className='flex flex-col'>
                                    <label className='mb-2 font-[500]'>State*</label>
                                    {/* <input type='text' name='state'
                                        value={signUpData.state} onChange={handleChange}
                                        required className='border p-2 rounded' /> */}
                                    <RegionDropdown
                                        classes='border p-2 rounded bg-white'
                                        country={preFillData.country}
                                        name='state'
                                        value={signUpData.state}
                                        onChange={selectRegion}
                                    />{formSubmitted && !signUpData.state && (
                                        <span className="text-red-600 pl-[1vw] text-[0.9em]">This field is required</span>
                                    )}
                                </div>
                                <div>
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <label className='mb-2 font-[500]'>Description</label>
                                <textarea value={signUpData.description}
                                    onChange={handleChange} name='description' maxLength='300' type='text' className='h-[15vh] w-full resize-none' />
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-[1vw] py-[1vh]'>
                                <div className='flex flex-col relative'>
                                    <label className='mb-2 font-[500]'>Password*</label>
                                    <div className='relative'>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            name='password'
                                            value={signUpData.password}
                                            onChange={handleChange}
                                            required
                                            className='border p-2 rounded w-full'
                                            maxLength='16'
                                        />
                                        {showPassword ? (
                                            <BsEyeSlashFill
                                                className='absolute right-[10px] top-[50%] transform -translate-y-1/2 cursor-pointer'
                                                onClick={togglePasswordVisibility}
                                            />
                                        ) : (
                                            <BsEyeFill
                                                className='absolute right-[10px] top-[50%] transform -translate-y-1/2 cursor-pointer'
                                                onClick={togglePasswordVisibility}
                                            />
                                        )}
                                        </div>
                                </div>
                                <div className='flex flex-col relative'>
                                    <label className='mb-2 font-[500]'>Confirm Password*</label>
                                    <div className='relative'>
                                        <input
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name='confirmPassword'
                                            value={confirmPassword}
                                            onChange={handleChange}
                                            required
                                            className='border p-2 rounded w-full'
                                            maxLength='16'
                                        />
                                        {showConfirmPassword ? (
                                            <BsEyeSlashFill
                                                className='absolute right-[10px] top-[50%] transform -translate-y-1/2 cursor-pointer'
                                                onClick={toggleConfirmPasswordVisibility}
                                            />
                                        ) : (
                                            <BsEyeFill
                                                className='absolute right-[10px] top-[50%] transform -translate-y-1/2 cursor-pointer'
                                                onClick={toggleConfirmPasswordVisibility}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <hr />
                        <div className='flex md:flex-row flex-col-reverse justify-between text-[0.8em]'>
                            <div className='  py-[1vh] '>
                                Length: {score.length ? score.length : 0}, &nbsp;
                                Your password is  {score.value ? <span className={`
                                ${score.value === 'Too weak' ? 'bg-red-600 text-white' :
                                        score.value === 'Weak' ? 'bg-orange-500' :
                                            score.value === 'Medium' ? 'bg-yellow-500' :
                                                'bg-green-600 text-white    '} px-[0.6vw] py-[0.6vh]
                            `}>
                                    {score.value}
                                </span> : <span className='border-b-[1px] w-2'></span>}
                            </div>
                            <div>{signUpData.password != '' && (confirmPassword === signUpData.password ? <div className='text-green-700 text-[0.9em]'>(Passwords match)</div> : <div className='text-red-600 text-[0.9em] '>(Passwords do not match)</div>)}
                            </div > </div>
                        <div className=' grid grid-cols-2 md:grid-cols-4 gap-y-[1vw] max-w-[850px] text-[0.8em]'>
                            <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("lowercase") ? 'text-blue-700' : 'text-gray-500'}`}><FaCheck />Lowercase</div>
                            <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("uppercase") ? 'text-blue-700' : 'text-gray-500'}`}><FaCheck />Uppercase</div>

                            <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("number") ? 'text-blue-700' : 'text-gray-500'}`}><FaCheck />Numbers</div>

                            <div className={`flex flex-row gap-x-1 items-center ${score.contains.includes("symbol") ? 'text-blue-700' : 'text-gray-500'}`}><FaCheck />Special Characters</div>

                        </div>

                        <div className='flex flex-col items-start py-4 text-[0.7em]'>
                            <div className='flex items-center '>
                            <p>By clicking submit, you agree to the terms and conditions of the <a href='/trial-agreement' target='_blank' className='text-blue-600 hover:underline'>Matisoft Cyber Security Labs Trial Agreement</a>, acknowledge that your data will be handled and transmitted as described in the <a href='/privacy-policy' target='_blank' className='text-blue-600 hover:underline'>Privacy Policy</a>, and confirm that you have fully read and understood it.</p>
                            </div>

                        </div>
                        <button type='submit' className='mt-4 bg-blue-600 border border-blue-600 border-1 text-white px-[2vw] py-[0.8vh] rounded hover:bg-white hover:text-blue-700'>Submit</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default CompleteSignUp;
