import React, { useEffect, useState } from "react";
import { styles } from "../styles";
import Slider from "react-slick";

const Award = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const awards = [
    { url: "/awards/raise_2020.jpg", data: "Declared India’s most promising AI Tech" },
    { url: "/awards/ecom_ciso.jpg", data: "Special recognition award" },
    { url: "/awards/Vizag-Fintech.jpg", data: "Won Best Emerging Technology Company among 12 countries" },
    { url: "/awards/jharkhand.jpg", data: "Won India’s Best Cyber Security company 2019" },
    { url: "/awards/India_mobile-removebg-preview.png", data: "Best Indian Cyber Security Company 2019" },
    { url: "/awards/electrify.jpg", data: "Selected as top 10 startups in India in 2018" },
    { url: "/awards/gujratAward.jpg", data: "Awarded Best Indian Startup challenge by Govt. of Gujarat" },
    { url: "/awards/IIGP-2.0.png", data: "Selected as Top 50 Innovation in India in 2018 " },
    { url: "/awards/up-startup.png", data: "1st Runner up in UP Startup Conclave Best Indian Startup in 2018" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className=" w-full  lg:h-screen h-full  mx-auto  bg-opacity-20 bg-gradient-to-r from-primary to-secondary  py-[11vh] awardsContainer" id="awards">

      <div
        className={`   inset-0 container mx-auto ${styles.paddingX} `}
      >
        <div className="award-underline pb-[3vh] pt-[3vh]">
          <h1 className="text-white text-4xl font-extrabold font-heading text-center mx-auto  font-high">Awards</h1></div>
        {/* <h1 className=" text-3xl   font-bold  text-center mt-7 pt-10  text-white font-high mx-auto useCase-underline">Awards</h1> */}
        <div className="  pt-[1vh]" >

          {windowWidth <= 640 ? (
            <Slider {...settings} className="mx-auto">
              {awards.map((item, index) => (
                <div key={index} className="mx-auto text-center"> {/* Key prop applied here */}
                  <img src={item.url} alt="" className='mx-auto max-w-[280px] max-h-[180px] object-contain' />
                  <h3 className={`mx-auto text-lg ${index === 1 ? 'text-center' : 'text-justify'} max-w-[280px] pt-2 text-white`}>{item.data}</h3>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="grid-container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-y-[7vh] gap-x-9 relative mx-auto">
              {awards.map((item, index) => (
                <div key={index} className="mx-auto"> {/* Key prop applied here */}
                  <div className="grid-item max-w-[250px] w-[95%] mx-auto">
                    <img src={item.url} alt="" className='max-w-[200px] h-[100px] object-contain mx-auto' />
                    <h3 className={`text-sm 2xl:text-lg px-1 pt-2 text-white text-justify mx-auto`}>{item.data}</h3>
                  </div>
                </div>
              ))}
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default Award;
