
import { ERROR_DIRECTORY_SERVER, REQUEST_DIRECTORY_SERVER, SET_DIRECTORY_SERVER } from "./directoryServer.action_type";
const intialState = {
    loading: false,
    action: null,
    directoryServer:[],
    dsMessage:null,
    error: null,
}
// console.log(directoryServer,"diresctSErver.");
const reducer = (state = intialState, action) => {
    // console.log(state.directoryServer,"action taken");
    switch (action.type) {
        case REQUEST_DIRECTORY_SERVER:
            return {
                ...state,
                loading: true,

            }
        case SET_DIRECTORY_SERVER:
            return {
                ...state,
                loading: false,
                directoryServer: action.payload.data,
                dsMessage:action.payload.message,
                error: null
            }
        case ERROR_DIRECTORY_SERVER:
            return {
                ...state,
                error: action.payload,
            }
        default: return state
    }


}
export { reducer };