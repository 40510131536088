import React from 'react'
import { styles } from '../styles';
import { FaArrowRight } from "react-icons/fa6";

const UspIr = () => {
  return (
    <div className='h-screen  relative w-full mx-auto py-20 heroir-background ' id='irusp'>
    <div className={`container absolute top-[5%] inset-0 ${styles.paddingX} mx-auto py-32 irfirst`}>
      {/* <p className={`${styles.sectionSubText} text-gray-400 `}>Matisoft Services</p> */}
      <h1 className='text-white font-heroHeading  md:max-w-[77%] lg:max-w-[70%] xl:max-w-[60%] 2xl:max-w-[77%] py-3 z-10 irfirst text-justify'>Incident Response Services</h1>
      <p className='font-subheading text-justify text-white  md:max-w-[75%] lg:max-w-[69%]  xl:max-w-[57%] 2xl:max-w-[75%]  z-20 ir-firstSub '>
      Our highly skilled and experienced team helps you recover from advanced cyber attacks like <span className='text-secondary text-lg 2xl:text-[28px]' > ShadowPad, PlugX, Stuxnet, WMI-Ghost, DJVU Ransomware, </span>etc.
      </p>
      <a href="/contact" className="btn btn-default flex flex-row text-black  uppercase ">Contact an expert </a>
      {/* UspIr */}
      </div>
    </div>
  )
}

export default UspIr