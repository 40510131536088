import React, { useEffect, useState } from "react";
import { styles } from "../styles";
import Slider from "react-slick";
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
const FeatureIr = () => {
 
  // Function to change the image based on the clicked point
  const data=[
    {SrNo:1,url:"/images/team.png",title:"  Highly skilled and experienced team"},
   
    
    {SrNo:2,url:"/images/dedication.png",title:"Dedicated research and reverse-engineering team"},
    {SrNo:3,url:"/images/contingency.png",title:"Best-In-Class Threat Intelligence"},
    // {SrNo:5,url:"/images/procedure.png",title:"Crisis Management"},
    // {SrNo:5,url:"/images/technical-support.png",title:"Hands-on remediation support"},
    {SrNo:4,url:"/images/quick-response.png",title:"Rapid Response Time"},
]
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000, 
  // fade: true,
  fade:false,
  centerMode: true,
  centerPadding: '0',
  easing: 'linear',
 
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
useEffect(() => {
  const handleResize = () => setWindowWidth(window.innerWidth);
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

  return (
    <div className="min-h-screen sm:h-full w-full bg-blue-100 mx-auto py-[7vh] mb-[3vh]" id="featuresIR">
      <div className={`container ${styles.paddingX} mx-auto `}>
        <h2 className="font-heroHeading text-primary text-center mx-auto py-[5vh]">
        Why Matisoft?
        </h2>
      
        <div>
{windowWidth <= 640 ? (
        <Slider {...settings} className="mx-auto">
          {data.map((item, index) => (
            <div>
            <div key={index} className="max-w-[260px] h-[45vh]  mx-auto text-center py-3   incidentsBox">
              {/* <img src={item.url} alt="" className=' mx-auto w-[230px] h-[110px] object-contain '/>
              
              <h3 className={`mx-auto text-xs text-center  max-w-[220px] px-1 pt-2 text-black`}>{item.heading}</h3> */}

              <img src={item.url} alt=""  className=' mx-auto w-1/3  object-contain py-14' />
              <h3 className={`mx-auto text-lg text-center   px-1 py-2 lg:py-9  text-black`}>{item.title}</h3>
              
            </div></div>
          ))}
        </Slider>
      ) : (
        <div className="grid-container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-y-5 gap-x-9 relative  mx-auto py-14 ">
        {data.map((item, index) => (
          <div className="relative">

            <div className="grid-item w-[100%] h-[47vh] incidentsBox py-5 " key={index} mx-auto >
              <img src={item.url} alt="" className=' mx-auto w-[38%]  object-contain py-3 absolute top-[15%] left-[50%] translate-x-[-50%]' />
              <h3 className={`mx-auto text-lg text-center   px-1 py-9  text-black absolute `}>{item.title}</h3>
            </div></div>
        ))}
      </div>
      )}
</div>
      </div>
    </div>
  );
};

export default FeatureIr;