
import { SET_ADMIN_AUTH, ERROR_ADMIN_AUTH, REQUEST_ADMIN_AUTH } from "./adminAuth.action_type";
import axios from "axios";

export const checkAdminAuth = (userId) => {
    try {
      return async (dispatch) => {
        dispatch({ type: REQUEST_ADMIN_AUTH });
  
        try {

          const response = await axios.post(`${process.env.REACT_APP_HOST_NAME}/cms/profile-view`,{
            userId:userId
          });     
            
          dispatch({ type: SET_ADMIN_AUTH, payload: response.data });
  
          // Handle the response data
        } catch (error) {
          // console.error('Error fetching data:', error);
          dispatch({ type: ERROR_ADMIN_AUTH, payload: error })
          // Handle errors
        }
      };
  
    } catch (error) {
      // console.log(error, 'edrfdfdsfdsd');
  
    }
  
  };