import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";

const CustomArrow = ({ color, direction,size, ...props }) => {
    return (
      <div
        {...props}
        style={{
          ...props.style,
          color: color,
          fontSize: "20px", // Customize other styles as needed
        }}
      >
        {direction === 'next' ? <IoIosArrowDroprightCircle/> : <IoIosArrowDropleftCircle/>}
      </div>
    );
  };
  
  export default CustomArrow;