import { act } from 'react-dom/test-utils';
import {
  REQUEST_UUID_DATA,
  RECEIVE_UUID_DATA,
  REQUEST_FAILED_UUID_DATA,
  SET_PAGE_NUMBER,
  SET_PAGE_SIZE,
  SET_SORT_OPTIONS,
  SET_FILTERS
} from './uuid.action_type';

const initialState = {
  sortBy: 'createdAt', // Default value for sortBy
  sortOrder: -1, // Default value for sortOrder
  // data: [],
  loading: false,
  uuidData: [],
  totalRecords:0,
  error: null,
  filters:{},
  pageSize: 100,
  pageNumber: 1,
  lastSortBy: null,
  lastSortOrder: null,
 
}; 

const reducer = (state = initialState, action) => {
  // console.log(state.error,"error");
  switch (action.type) {
    case REQUEST_UUID_DATA:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_UUID_DATA:
      return {
        ...state,
        loading: false,
        uuidData: action.payload.alerts,
       totalRecords:action.payload.totalRecords,
        error: null,
      };
    case REQUEST_FAILED_UUID_DATA:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_SORT_OPTIONS:
      return {
        ...state,
        lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
        lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
      case SET_FILTERS:{
        return {
          ...state,
          filters:action.payload,
        }
      }
    default:
      return state;
  }
};

export { reducer };
