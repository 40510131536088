import React, { useState } from 'react'
import { styles } from '../styles'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CustomArrow from '../utils/Arrow';
const Customers = () => {
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    // fade: true,
    fade: false,
    centerMode: true,
    centerPadding: '0',
    easing: 'linear',
    nextArrow: <CustomArrow color="skyblue" direction="next" />,
    prevArrow: <CustomArrow color="skyblue" direction="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const partners = [
    { name: "ONGC", url: "/images/ONGC_Logo.svg.png" },
    { name: "Indian Navy", url: "/images/navy.png" },
    { name: "ONGC Videsh", url: "/images/ongcVidesh.jpg" },
    { name: "IIT Delhi", url: '/images/iitd.png' },
    { name: "Power Grid", url: "/images/powergrid-logo.png" },


  ]
  return (
    <div className=" w-full lg:h-screen h-full   mx-auto bg-opacity-20 bg-white " id='customers'>

      <div
        className={`   inset-0  container mx-auto ${styles.paddingX} py-[10vh]  mb-0  `}
      >
        <div className="useCase-underline  py-[5vh]">
          <h1 className="text-secondary text-4xl font-extrabold font-heading text-center mx-auto  font-high">Trusted By</h1></div>

        { /* {windowWidth <= 640 ?  */}

        <Slider {...settings} className="mx-auto">
          {partners.map((item, index) => (
            <div key={index} className="relative py-[2vh]"> {/* Key prop applied here */}
              <div className="w-[97%] relative max-h-[40vh] py-[2vh]">
                <img
                  src={item.url}
                  alt={item.name}
                  className="h-[23vh] w-[80%] object-scale-down mx-auto"
                />
                <p className="bottom-[2%] text-center text-[1.3em] pt-[2vh] text-gray-700">
                  {item.name}
                </p>
              </div>
            </div>
          ))}
        </Slider>

        <p className='text-lg  2xl:text-2xl  text-justify font-medium pt-[10vh]'>Amongst our many customers, we take pride in serving our esteemed customers and contributing to national security.</p>
      </div>
    </div>
  )
}

export default Customers