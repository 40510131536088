import React from "react";
import { styles } from "../styles";

const IR = () => {
  return (
    <div className=" w-full h-full xl:h-screen bg-white-100 " id="irtheory">
      <div className={`container ${styles.paddingX} mx-auto py-32`}>
        <div className="flex md:flex-row flex-col items-center justify-around gap-x-[3wh] ">
          <div className="w-[100%] md:w-[54%] xl:w-[52%]">
            <h1 className="font-heroHeading">Incident Response Services</h1>
            <p className="text-sm sm:text-sm xl:text-lg 2xl:text-xl 2xl:font-medium  text-primary py-[2vh] font-normal text-justify ">
              Incident response services refer to the organized approach or plan
              implemented by Matisoft to address and manage security
              incidents effectively for our customers. These services are designed
              to minimize damage, reduce recovery time, and handle various types
              of incidents, such as virus attacks, data breaches, system
              compromises, and other security-related issues.
            </p>
          </div>
          <div className=" md:w-[45%]" >
            <img src="/images/analytics-3088958_1920.jpg" alt="ir-service" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IR;