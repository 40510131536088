
import { REQUEST_ACTION, ERROR_ACTION, SET_ACTION } from "./actionCenter.action_type"
const intialState = {
    loading: false,
    action: null,
    actionData:[],
    actionMessage:null,
    error: null,
}

const reducer = (state = intialState, action) => {
    // console.log(action,"action taken");
    switch (action.type) {
        case REQUEST_ACTION:
            return {
                ...state,
                loading: true,

            }
        case SET_ACTION:
            return {
                ...state,
                loading: false,
                actionData: action.payload.data,
                actionMessage:action.payload.message,
                error: null
            }
        case ERROR_ACTION:
            return {
                ...state,
                error: action.payload,
            }
        default: return state
    }


}
export { reducer };