
export const REQUEST_DASHBOARD_DATA = 'REQUEST_DASHBOARD_DATA';
export const RECEIVE_DASHBOARD_DATA = 'RECEIVE_DASHBOARD_DATA';
export const REQUEST_FAILED_DASHBOARD_DATA = 'REQUEST_FAILED_DASHBOARD_DATA';
export const SET_SORT_OPTIONS_DASHBOARD = 'SET_SORT_OPTIONS_DASHBOARD';
export const SET_PAGE_SIZE_DASHBOARD = 'SET_PAGE_SIZE_DASHBOARD';
export const SET_PAGE_NUMBER_DASHBOARD = 'SET_PAGE_NUMBER_DASHBOARD';
export const SET_FILTER_DASHBOARD = 'SET_FILTER_DASHBOARD';
export const REQUEST_DASHBOARD_INFECTED_DATA = 'REQUEST_DASHBOARD_INFECTED_DATA';
export const RECEIVE_DASHBOARD_INFECTED_DATA = 'RECEIVE_DASHBOARD_INFECTED_DATA';
export const REQUEST_FAILED_DASHBOARD_INFECTED_DATA = 'REQUEST_FAILED_DASHBOARD_INFECTED_DATA';
export const REQUEST_DASHBOARD_WHITELIST = 'REQUEST_DASHBOARD_WHITELIST';
export const RECEIVE_DASHBOARD_WHITELIST = 'RECEIVE_DASHBOARD_WHITELIST';
export const REQUEST_FAILED_DASHBOARD_WHITELIST = 'REQUEST_FAILED_DASHBOARD_WHITELIST';
export const REQUEST_DASHBOARD_QUARANTINE = 'REQUEST_DASHBOARD_QUARANTINE';
export const RECEIVE_DASHBOARD_QUARANTINE = 'RECEIVE_DASHBOARD_QUARANTINE';
export const REQUEST_FAILED_DASHBOARD_QUARANTINE = 'REQUEST_FAILED_DASHBOARD_QUARANTINE';
export const REQUEST_DASHBOARD_DELETE = 'REQUEST_DASHBOARD_DELETE';
export const RECEIVE_DASHBOARD_DELETE = 'RECEIVE_DASHBOARD_DELETE';
export const REQUEST_FAILED_DASHBOARD_DELETE = 'REQUEST_FAILED_DASHBOARD_DELETE';
export const REQUEST_DASHBOARD_TOBEANALYSED = 'REQUEST_DASHBOARD_TOBEANALYSED';
export const RECEIVE_DASHBOARD_TOBEANALYSED = 'RECEIVE_DASHBOARD_TOBEANALYSED';
export const REQUEST_FAILED_DASHBOARD_TOBEANALYSED = 'REQUEST_FAILED_DASHBOARD_TOBEANALYSED';
export const REQUEST_DASHBOARD_ALERTS_DATA = 'REQUEST_DASHBOARD_ALERTS_DATA';
export const REQUEST_FAILED_DASHBOARD_ALERTS_DATA = 'REQUEST_FAILED_DASHBOARD_ALERTS_DATA'
export const RECEIVE_DASHBOARD_ALERTS_DATA = 'RECEIVE_DASHBOARD_ALERTS_DATA';
export const REQUEST_FAILED_DASHBOARD_CUSTOMSOLUTION = 'REQUEST_FAILED_DASHBOARD_CUSTOMSOLUTION';
export const REQUEST_DASHBOARD_CUSTOMSOLUTION = 'REQUEST_DASHBOARD_CUSTOMSOLUTION';
export const RECEIVE_DASHBOARD_CUSTOMSOLUTION = 'RECEIVE_DASHBOARD_CUSTOMSOLUTION';
export const REQUEST_FAILED_DASHBOARD_ALLALERTS = 'REQUEST_FAILED_DASHBOARD_ALLALERTS';
export const REQUEST_DASHBOARD_ALLALERTS = 'REQUEST_DASHBOARD_ALLALERTS';
export const RECEIVE_DASHBOARD_ALLALERTS = 'RECEIVE_DASHBOARD_ALLALERTS';
export const REQUEST_FAILED_DASHBOARD_SCAN = 'REQUEST_FAILED_DASHBOARD_SCAN';
export const REQUEST_DASHBOARD_SCAN = 'REQUEST_DASHBOARD_SCAN';
export const RECEIVE_DASHBOARD_SCAN = 'RECEIVE_DASHBOARD_SCAN';
