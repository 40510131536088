import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useFetchers } from 'react-router-dom';
import { IoIosMenu, IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { MdDashboardCustomize } from "react-icons/md";
import { FaLayerGroup, FaRegUser } from "react-icons/fa";
import { IoMenuSharp } from "react-icons/io5";
import '../css/sidebar.css'
import { FaLockOpen } from "react-icons/fa6";
import { TbCloudDownload } from "react-icons/tb";
import { checkAdminAuth } from '../../Redux/adminAuth/adminAuth.action';
import { FaKey } from "react-icons/fa6";
import { MdKeyboardArrowUp } from "react-icons/md";
import { CgOrganisation } from "react-icons/cg";
import { FaUserClock } from "react-icons/fa";
import { TbUserQuestion } from "react-icons/tb";
import { HiOutlineBuildingOffice } from "react-icons/hi2";

const SidebarRelease = ({ isExpanded, toggleSidebar }) => {
    const dispatch = useDispatch();
    const { companyId, userId, adminAuthData } = useSelector((state) => ({
        companyId: state.companyid.companyId,
        userId: state.companyid.userId,
        adminAuthData: state.adminAuth.adminAuthData
    }));
    // console.log(adminAuthData, "admin data");

    const [showSetting, setShowSetting] = useState(false);
    const [lowerWindow, setLowerWindow] = useState(false);
    const [data, setData] = useState(null);
    // const [isExpanded, setIsExpanded] = useState(true);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [activeItem, setActiveItem] = useState(null);

    // const toggleSidebar = () => {
    //     setIsExpanded(!isExpanded);
    // };
    useEffect(() => {
        if (userId) {  // Check if userId exists before dispatching
            dispatch(checkAdminAuth(userId));
        }
    }, [userId])
    const handleItemClick = (item) => {
        setActiveItem(activeItem === item ? null : item);
    };

    const handleMouseEnter = (item) => {
        setHoveredItem(item);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };
    const [activeItemCollapse, setActiveItemCollapse] = useState(null);

    const handleMouseEnterCollapse = (id) => {
        setActiveItemCollapse(id);
    };

    const handleMouseLeaveCollapse = (event) => {
        setActiveItemCollapse(null);
    };
    const [sidebarCompanyShow, setSidebarCompanyShow] = useState(false);


    return (
        <>
            {isExpanded ?
                <div className={`sidebar-container bg-[#0d1321] h-screen ${isExpanded ? 'w-1/5' : 'w-1/12'}`}>
                    <div className='px-[1vw] pt-[1vh]'>
                        <IoMenuSharp className='text-white text-[26px]' onClick={toggleSidebar} />
                    </div>

                    <div className='flex flex-row items-center justify-center h-[10%] mx-auto'>
                        <img src='/small-logo.png' className={`'flex h-[60%] px-[3%] ${isExpanded ? 'h-[60%]' : 'w-[26px] object-contain'}`} alt='company-logo' />
                        {isExpanded && (<div className='flex flex-col items-start'>
                            <span className='text-[0.9em] text-white'>Matisoft AMS Cloud Platform</span>
                            <span className='text-secondary text-[0.7em]'>

                                {adminAuthData?.company_name ? <a href='/cms/dashboard'><span>{adminAuthData?.company_name}</span></a> : <a href='/cms/dashboard'><span>Matisoft Cyber Security Labs</span></a>
                                }
                            </span>
                        </div>)}
                    </div>

                    <div className='flex flex-col items-center text-gray-200 pt-[6vh]'>
                        <ul className='w-full mx-auto'>
                            {/* <li className={`hover:bg-blue-300 hover:text-black ${activeItem === 'dashboard' ? 'bg-blue-300 text-black' : ''}`}>
                    <NavLink to={`/cms/company/dashboard/${companyId}/${userId}`} className="nav-link px-[1vw] nav-toggle flex flex-row justify-between items-center py-[2vh]" onClick={() => handleItemClick('dashboard')}>
                        <span className='flex flex-row justify-start items-start gap-x-[1vw]'>
                            <MdDashboardCustomize className='font-thin' />
                            {isExpanded && <span className="title text-[0.9em]">Dashboard</span>}
                       {!isExpanded && <div className='relative hidden hover:block'><span className="absolute left-0 title text-[0.9em] ">Dashboard</span></div>}
                        </span>
                        {isExpanded && <IoIosArrowForward />}
                    </NavLink>
                </li> */}

                            <li
                                className={`nav-item ${hoveredItem === 'release' ? 'bg-blue-300 text-black' : ''}`}
                                onMouseEnter={() => handleMouseEnter('release')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="nav-link nav-toggle flex flex-row  justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('company')}>
                                    <span className='flex flex-row justify-start items-start gap-x-[1vw] text-yellow'>
                                        <FaLockOpen className='font-thin text-[0.9em]' />
                                        {isExpanded && <span className="title text-[0.9em]">Release</span>}
                                    </span>
                                    {isExpanded && <IoIosArrowForward />}
                                </div>
                                {(activeItem === 'release' || hoveredItem === 'release') && (
                                    <ul className='ml-[2vw]'>
                                        <li className='text-[0.9em]   hover:text-black'>
                                            <a href={`/cms/release/manage/${userId}`} className='pl-[2vw]'>
                                                Manage Release
                                            </a>
                                        </li>
                                        {/* <li className='text-[0.9em] hover:text-black'>
                                <NavLink to={`/cms/company/create`} target="_blank" rel="noopener noreferrer" className='pl-[2vw]'>
                                    Add Company
                                </NavLink>
                            </li> */}
                                    </ul>
                                )}
                            </li>

                            <li
                                className={`nav-item ${hoveredItem === 'company' ? 'bg-blue-300 text-black' : ''}`}
                                onMouseEnter={() => handleMouseEnter('company')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('company')}>
                                    <span className='flex flex-row justify-start items-start gap-x-[1vw] text-yellow'>
                                        <HiOutlineBuildingOffice className='font-thin text-[1em]' />
                                        {isExpanded && <span className="title text-[0.9em]">Company</span>}
                                    </span>
                                    {isExpanded && <IoIosArrowForward />}
                                </div>
                                {(activeItem === 'company' || hoveredItem === 'company') && (
                                    <ul className='ml-[2vw]'>
                                        <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em]  hover:text-black'>
                                            <a href={`/cms/company/list`} className='pl-[2vw]'>
                                                View Companies
                                            </a>
                                        </li>
                                        <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em]  hover:text-black'>
                                            <a href={`/cms/company/all`} className='pl-[2vw]'>
                                                View All Companies
                                            </a>
                                        </li>
                                        <li className='text-[0.9em] hover:text-black'>
                                            <a href={`/cms/company/create`} target="_blank" rel="noopener noreferrer" className='pl-[2vw]'>
                                                Add Company
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li
                                className={`nav-item ${hoveredItem === 'registeredUser' ? 'bg-blue-300 text-black' : ''}`}
                                onMouseEnter={() => handleMouseEnter('registeredUser')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('company')}>
                                    <span className='flex flex-row justify-start items-start gap-x-[1vw] text-yellow'>
                                        <FaRegUser className='font-thin text-[0.9em]' />
                                        {isExpanded && <span className="title text-[0.9em]">Registered Users</span>}
                                    </span>
                                    {isExpanded && <IoIosArrowForward />}
                                </div>
                                {(activeItem === 'registeredUser' || hoveredItem === 'registeredUser') && (
                                    <ul className='ml-[2vw]'>
                                        <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em]  hover:text-black'>
                                            <a href={`/cms/webusers/new-registered-users`} className='pl-[2vw]'>
                                                Verify New Users
                                            </a>
                                        </li>
                                        <li className='text-[0.9em] hover:text-black'>
                                            <a href={`/cms/webusers/all-registered-users`} target="_blank" rel="noopener noreferrer" className='pl-[2vw]'>
                                                Verified Users
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            {/* <li
                            className={`nav-item ${hoveredItem === 'licenseCreate' ? 'bg-blue-300 text-black' : ''}`}
                            onMouseEnter={() => handleMouseEnter('licenseCreate')}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('company')}>
                                <span className='flex flex-row justify-start items-start gap-x-[1vw] text-yellow'>
                                    <FaKey className='font-thin text-[0.9em]' />
                                    {isExpanded && <span className="title text-[0.9em]">License Management</span>}
                                </span>
                                {isExpanded && <IoIosArrowForward />}
                            </div>
                            {(activeItem === 'licenseCreate' || hoveredItem === 'licenseCreate') && (
                                <ul className='ml-[2vw]'>
                                    <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em]  hover:text-black'>
                                        <NavLink to={`/cms/createLicense/${userId}`} className='pl-[2vw]'>
                                            License Management
                                        </NavLink>
                                    </li>
                                    <li className='text-[0.9em]  hover:text-black'>
                                            <NavLink to={`/cms/licenseHistory/null/${userId}`} className='pl-[2vw]'>
                                                License Transaction History
                                            </NavLink>
                                        </li>
                                </ul>
                            )}
                        </li> */}
                            <li
                                className={`nav-item ${hoveredItem === 'internalUsers' ? 'bg-blue-300 text-black' : ''}`}
                                onMouseEnter={() => handleMouseEnter('internalUsers')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('company')}>
                                    <span className='flex flex-row justify-start items-start gap-x-[1vw] text-yellow'>
                                        <FaRegUser className='font-thin text-[0.9em]' />
                                        {isExpanded && <span className="title text-[0.9em]">Internal users</span>}
                                    </span>
                                    {isExpanded && <IoIosArrowForward />}
                                </div>
                                {(activeItem === 'internalUsers' || hoveredItem === 'internalUsers') && (
                                    <ul className=' '>
                                        <li className='text-[0.9em] border-b-blue-400 border-b-[0.1em]  hover:text-black'>
                                            <a href={`/cms/manage-users`} className='pl-[2vw]'>
                                                Manage
                                            </a>
                                        </li>
                                        <li className='text-[0.9em] hover:text-black'>
                                            <a href={`/cms/manage-users/create`} target="_blank" rel="noopener noreferrer" className='pl-[2vw]'>
                                                Create
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            {/* <li
                    className={`nav-link ${hoveredItem === 'deployment' ? 'bg-blue-300 text-black' : ''}`}
                    onMouseEnter={() => handleMouseEnter('deployment')}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="nav-link nav-toggle flex flex-row justify-between px-[1vw] items-center py-[2vh]" onClick={() => handleItemClick('deployment')}>
                        <NavLink className='flex flex-row justify-start items-start gap-x-[1vw] text-yellow' to='/cms/installer'>
                          
                            <TbCloudDownload className=' text-[1em]'/>
                            {isExpanded && <span className="title text-[0.9em]">Product Deployment</span>}
                        </NavLink>
                        {isExpanded && <IoIosArrowForward />}
                    </div>
                </li> */}

                        </ul>
                    </div>
                    {lowerWindow && isExpanded && (
                        <div className='h-[26vh] px-[2vw] pt-[4vh] absolute bottom-[10vh] font-[400] text-[0.9em] text-[#A6ADBA] bg-[#2C3850] w-full flex flex-col items-start gap-y-[1vh]' onClick={() => setLowerWindow(!lowerWindow)}>
                            <div className='hover:text-white'>
                                <a href={`/cms/update-profile`}>Manage your account</a>
                            </div>
                            <div className='hover:text-white'>
                                <a href='/cms/company/list'>Manage Workspaces</a>
                            </div>
                            {/* <div className='hover:text-white'>
                        <NavLink to={`/cms/licenseInfo/${userId}`}> License Info </NavLink>
                    </div> */}
                            <div className='hover:text-white'>
                                <a href='/cms/logout'>Sign out</a>
                            </div>
                        </div>
                    )}
                    <div className='h-[10vh] px-[0.5vw] pt-[2vh] absolute bottom-0 bg-[#2C3850] w-full flex flex-row justify-between text-white items-start' onClick={() => setLowerWindow(!lowerWindow)}>
                        <div className='flex flex-row items-start'>
                            <FaRegUser className='text-[0.9em]' />
                            {isExpanded && (
                                <div className='flex flex-col ml-[0.6vw]'>
                                    <div className='text-[0.9em]'>{adminAuthData ? adminAuthData?.email : ''}</div>
                                    <div className='text-[0.8em] font-[300] text-gray-300'>{adminAuthData ? adminAuthData?.role : ''}</div>
                                </div>
                            )}
                        </div>
                        {isExpanded && <IoIosArrowUp />}
                    </div>
                </div>

                :
                <div className='collapsed-view bg-[#0d1321] h-screen w-[4vw] fixed z-[49] flex flex-col justify-between'>
                    <div>
                        <div className='px-[1vw] pt-[1vh]'>
                            <IoIosMenu className='text-[26px] text-white cursor-pointer' onClick={toggleSidebar} />
                        </div>

                        {/* Logo at the top */}
                        <div className="flex justify-center py-[2vh]">
                            <a href='/cms/dashboard'>
                                <img
                                    src='/small-logo.png'
                                    className={`h-[60px] ${isExpanded ? 'w-[80%]' : 'w-[26px] object-contain'}`}
                                    alt='company-logo'
                                />
                            </a>
                        </div>

                        {/* Sidebar Items */}
                        <div className="flex flex-col text-white items-center pt-[5vh] gap-y-[2vh]">
                            {/* Sidebar Item 1 */}
                            <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                                <div
                                    className={`p-3 ${activeItemCollapse === 1 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                    onMouseEnter={() => handleMouseEnterCollapse(1)}
                                >
                                    <FaLockOpen className='font-thin text-[0.9em] hover:cursor-pointer' />
                                </div>
                                {activeItemCollapse === 1 && (
                                    <div className="absolute top-0 left-full w-[17vw] bg-[#62c1e4] transition-opacity z-[101]">
                                        <a href="#" className="block p-2 text-[#0d1321] transition-colors">Release</a>
                                        <a href="/cms/release" className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Manage Release</a>

                                    </div>
                                )}
                            </div>

                            {/* Sidebar Item 2 */}
                            <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                                <div
                                    className={`p-3 ${activeItemCollapse === 2 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                    onMouseEnter={() => handleMouseEnterCollapse(2)}
                                >
                                    <HiOutlineBuildingOffice className='font-thin text-[1em] hover:cursor-pointer' />
                                </div>
                                {activeItemCollapse === 2 && (
                                    <div className="absolute top-0 left-full w-[17vw] bg-[#62c1e4] transition-opacity z-[101]">
                                        <a href="#" className="block p-2 text-[#0d1321] transition-colors">Company</a>
                                        <a href="/cms/company/list" className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">View Companies</a>
                                        <a href="/cms/company/all" className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">View All Companies</a>

                                        {/* <a href={`/cms/allOrg/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">View All Organisations</a> */}
                                        <a href="/cms/company/create" className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Add Company</a>
                                    </div>
                                )}
                            </div>

                            {/* Sidebar Item 3 */}
                            <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                                <div
                                    className={`p-3 ${activeItemCollapse === 3 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                    onMouseEnter={() => handleMouseEnterCollapse(3)}
                                >
                                    <FaRegUser className='font-thin text-[0.9em] hover:cursor-pointer' />
                                </div>
                                {activeItemCollapse === 3 && (
                                    <div className="absolute top-0 left-full w-[17vw] bg-[#62c1e4] transition-opacity z-[101]">
                                        <a href="#" className="block p-2 text-[#0d1321] transition-colors">Registered Users</a>
                                        <a href={`/cms/webusers/new-registered-users`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Verify New Users</a>
                                        <a href={`/cms/webusers/all-registered-users`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Verified Users</a>

                                    </div>
                                )}
                            </div>

                            {/* Sidebar Item 4 */}
                            {/* <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                            <div
                                className={`p-3 ${activeItemCollapse === 4 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                onMouseEnter={() => handleMouseEnterCollapse(4)}
                            >
                                <FaKey className='font-thin text-[0.9em] hover:cursor-pointer' />
                            </div>
                            {activeItemCollapse === 4 && (
                                <div className="absolute top-0 left-full w-[17vw] bg-[#62c1e4] transition-opacity z-[101]">
                                    <a href="#" className="block p-2 text-[#0d1321] transition-colors">License Management</a>
                                    <a href={`/cms/createLicense/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">License Management</a>
                                    <a href={`/cms/licenseHistory/null/${userId}`} className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">License Transaction History</a>

                                </div>
                            )}
                        </div> */}

                            {/* Sidebar Item 5 */}
                            <div className="relative" onMouseLeave={handleMouseLeaveCollapse}>
                                <div
                                    className={`p-3 ${activeItemCollapse === 5 ? 'bg-[#62c1e4] text-[#0d1321]' : ''} transition-colors`}
                                    onMouseEnter={() => handleMouseEnterCollapse(5)}
                                >
                                    <FaRegUser className='font-thin text-[0.9em] hover:cursor-pointer' />
                                </div>
                                {activeItemCollapse === 5 && (
                                    <div className="absolute top-0 left-full w-[17vw] bg-[#62c1e4] transition-opacity z-[101]">
                                        <a href="#" className="block p-2 text-[#0d1321] transition-colors">Internal Users</a>
                                        <a href="/cms/manage-users" className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Manage</a>
                                        <a href="/cms/manage-users/create" className="block p-2 bg-[#0d1321] text-white hover:text-[#4fd0ff] transition-colors border-l border-b border-r border-gray-500">Create</a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Bottom User Icon */}
                    <div className='relative'>
                        <div className='absolute bottom-0 left-0 h-[9vh]  w-full flex justify-center py-[2vh] bg-[#2C3850]' onClick={() => setLowerWindow(!lowerWindow)}>
                            <MdKeyboardArrowUp className='text-white' />     <FaRegUser className='text-white cursor-pointer' />
                        </div>
                        {lowerWindow && (
                            <div className='h-[26vh] px-[2vw] pt-[4vh] w-[15vw] z-50 absolute left-[4vw] bottom-[9vh] font-[400] text-[0.9em] text-[#A6ADBA] bg-[#2C3850] flex flex-col items-start gap-y-[1vh]' onClick={() => setLowerWindow(!lowerWindow)}>
                                <div className='hover:text-white'>
                                    <a href={`/cms/update-profile`}>Manage your account</a>
                                </div>
                                <div className='hover:text-white'>
                                    <a href='/cms/company/list'>Manage Workspaces</a>
                                </div>
                                <div className='hover:text-white'>
                                    <a href='/cms/logout'>Sign out</a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>


            }</>
    );
};

export default SidebarRelease;
