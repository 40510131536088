import React from 'react'
import UspIr from '../components/UspIr';
import FeatureIr from '../components/FeatureIr';
import '../css/irservice.css'
import IR from '../components/IR';
import HistoryIr from '../components/HistoryIr';
import CaseStudyIr from '../components/CaseStudyIr';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../Header1';
import Footer from '../Footer';
import CookiesBanner from '../components/Cookie';
import { Helmet } from 'react-helmet';
const Irservice = () => {
  return (
   <>
    <Helmet>
        <title>Matisoft | Incident Response Services</title>
      </Helmet>
   <Header/>
   <UspIr/>
   <IR/>
   <HistoryIr/>
   <FeatureIr/>
   <CaseStudyIr/>
   <CookiesBanner/>
   <Footer/>
   </>
  )
}

export default Irservice;