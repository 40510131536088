import { SET_PRODUCT_DATA } from './linkedDeviceDetail.action';

const initialState = {
  productCode: null,
  deviceCount: null,
  productName: null,
  dueDate: null,
  userId: null,
  licenseId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export { reducer};
