import { act } from 'react-dom/test-utils';
import {
  REQUEST_DASHBOARD_DATA,
  REQUEST_FAILED_DASHBOARD_DATA,
  RECEIVE_DASHBOARD_DATA,
  SET_PAGE_NUMBER_DASHBOARD,
  SET_SORT_OPTIONS_DASHBOARD,
  SET_PAGE_SIZE_DASHBOARD,
  SET_FILTER_DASHBOARD

} from './dashboard.action_type';

const initialState = {
  sortBy: 'createdAt', // Default value for sortBy
  sortOrder: -1, // Default value for sortOrder
  // data: [],
  loading: false,
  linkingData: [],
  totalRecords: 0,
  error: null,
  linkedDevices:0,
  unlinkedDevices:0,
  filters: {},
  pageSize: 100,
  pageNumber: 1,
  lastSortBy: null,
  lastSortOrder: null,
};

const reducer = (state = initialState, action) => {
  // console.log(state.filters, "datainDASHBOARDsssss");
  switch (action.type) {
    case REQUEST_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_DASHBOARD_DATA:
      return {
        ...state,
        loading: false,
       linkingData: action.payload.devices,
        linkedDevices:action.payload.linkedDevices,
        unlinkedDevices:action.payload.unlinkedDevices,
        totalRecords: action.payload.totalRecords,
        error: null,
      };
    case REQUEST_FAILED_DASHBOARD_DATA:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_SORT_OPTIONS_DASHBOARD:
      return {
        ...state,
        lastSortBy: state.sortBy, // Save current sortBy as lastSortBy
        lastSortOrder: state.sortOrder, // Save current sortOrder as lastSortOrder
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    case SET_PAGE_SIZE_DASHBOARD:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SET_PAGE_NUMBER_DASHBOARD:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case SET_FILTER_DASHBOARD:
      return {
        ...state,
        filters: action.payload,
      }
    default:
      return state;
  }
};

export { reducer };
